import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import {RiMapPinLine, RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'

import { Menu } from '@headlessui/react'

import {HouseContext} from './HouseContext'

const CountryDropdown = () => {
  const {country, setCountry,countries} = useContext(HouseContext)

 const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false)
  return <Menu as='div' className='dropdown relative' >
    <Menu.Button className='dropdown-btn w-full text-left' onClick={()=> setIsOpen(!isOpen) }>
      <RiMapPinLine className='dropdown-icon-primary' />
      <div>
        <div className='text-[14px] hover:text-[#413d42]  leading-tight'>
          {country === 'ადგილმდებარეობა (ნებისმიერი)' && t('location') }
          {country === 'თბილისი' && t('tbilisi') }
          {country === 'საგურამო' && t('saguramo') }
          {country === 'წავკისი' && t('tsavkisi') }
          </div>
        <div className='text-[10px]'>{t('location')}</div>
      </div>
        {
          isOpen ? (
            <RiArrowUpSLine 
            className='dropdown-icon-secondary' />
            ) : (
            <RiArrowDownSLine className='dropdown-icon-secondary' />
          )
        }
    </Menu.Button>

        <Menu.Items className='dropdown-menu'>
          {countries.map((country,index) => {
            return (
              <Menu.Item onClick={() => setCountry(country)}
               className='cursor-pointer hover:text-violet-700 transition' as='li' key={index}>
                {country === 'თბილისი' && t('tbilisi') }
          {country === 'საგურამო' && t('saguramo') }
          {country === 'წავკისი' && t('tsavkisi') }
                </Menu.Item>
            )
          })}
        </Menu.Items>

    </Menu>;
};

export default CountryDropdown;
