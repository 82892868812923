import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {BsFillTelephoneFill} from 'react-icons/bs';
import {AiFillMail} from 'react-icons/ai'
import { BsInstagram } from 'react-icons/bs'
import { HouseContext } from './HouseContext';

const Footer = () => {
  const { t, i18n } = useTranslation();
   const {showHouses } = useContext(HouseContext);

  return <footer className='bg-[#141414] justify-center items-center  lg:flex lg:flex-col py-8 text-[#e4e1e1] '>
    <div className='flex gap-11 md:px-11 px-4 text-[0.9rem] lg:px-32 mb-6'>
      <div className='flex flex-col'>
      <p className='text-[0.9rem] max-w-[14em] md:max-w-[30em] mb-2'>{t('footer')} <span className='text-violet-700'>"Elysium Luxury Properties"</span></p>
      <div>
        <BsFillTelephoneFill className='inline-block mr-2' /><p className='inline-block'>598 99 18 18</p>
        </div>
      <div>
      <AiFillMail className='inline-block mr-2'/><p className='inline-block'>tikadgebuadze@yahoo.com</p>
      <div>
      <BsInstagram className='text-white inline-block cursor-pointer mr-2  hover:text-violet-600' />
      <a className='md:hidden mr-6 hover:text-violet-600' href='https://www.instagram.com/elysiumluxuryproperties' target='_blank'>
        Instagram</a>
        
        </div>
        </div>
        </div>
        <div>
          <p className='font-semibold tracking-widest mb-2 text-white'>{t('ads')}</p>
          <ul>
            <li>{t('flats')}</li>
            <li onClick = {showHouses} className='cursor-pointer hover:text-violet-600'>{t('houses')}</li>
            <li>{t('lands')}</li>
            <li>{t('commercial')}</li>
          </ul>
        </div>
        <div className='hidden md:flex flex-col'>
          <p className='font-semibold tracking-widest mb-2 text-white'>{t('flats')}</p>
          <ul>
            <li>ბინები ვაკეში</li>
            <li>ბინები საბურთალოზე</li>
            <li>ბინები დიღომში</li>
            <li>ბინები გლდანში</li>
            <li>ბინები ვარკეთილში</li>
            
          </ul>
        </div>
        <div className='hidden md:flex flex-col'>
          <p  className='font-semibold cursor-pointer tracking-widest mb-2 text-white'>{t('houses')}</p>
          <ul>
            <li>სახლები დიღომში</li>
            <li>სახლები საგურამოში</li>
            <li>სახლები წავკისში</li>
            <li>სახლები წყნეთში</li>
          </ul>
        </div>
    </div>
    
    <div className='border-t-[1px] text-center md:px-11 px-4 lg:px-32 pt-6 border-[white]'>
      <span className=' font-lora'>Elysium Properties</span> &copy; 2023. {t('rights')}</div>
      <div>
      
     
      
      </div>
  </footer>;
};

export default Footer;
