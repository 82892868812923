import React, { useState, useEffect } from 'react';
import {Routes, Route} from 'react-router-dom'

// import i18n (needs to be bundled ;)) 
import './i18n';

import Header from './components/Header'
import Footer from './components/Footer'

import Home from './pages/Home'
import PropertyDetails from './pages/PropertyDetails'
import { useTranslation } from 'react-i18next';



const App = () => {

 const { t } = useTranslation();


  return (
  <div className='w-full mx-auto bg-white'>
    <Header />
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/property/:id' element={<PropertyDetails />} />
    </Routes>
 
    <Footer />
    
    </div>
);
};

export default App;
