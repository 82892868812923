import React from 'react'

//import swiper react components

import Search from '../components/Search'

import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'react-i18next';
import 'swiper/css'
import 'swiper/css/effect-fade'

import {EffectFade, Autoplay} from 'swiper'

import bg1 from '../assets/img/bg.jpg'
import bg2 from '../assets/img/bg2.jpg'
import bg3 from '../assets/img/bg3.jpg'

const slides = [
  {
   
    bg: bg1,

  },
  {

    bg: bg2,

  },
  {

    bg: bg3,

  },
]

const HeroSlider = () => {

  const { t, i18n } = useTranslation();

    return (
     <Swiper modules={[EffectFade, Autoplay]} 
     effect={'fade'}
     Loop={true}
     autoplay={{
      delay: 3000,
      disableOnInteraction: false,
     }}
     className='heroSlider h-[600px] lg:h-[860px]'>
      {slides.map((slide,index) => {
        //desctucture slide
        const {title, bg, btnText} = slide
        return (<SwiperSlide key={index} className='h-full  relative flex justify-center items-center'>
          <div className='absolute z-10 w-full h-full bg-black/40'></div>
          <div className='z-50 text-white  text-center'>
            
      
           
            
          </div>
          <div className='absolute top-0   w-full h-full'>
            <img className='object-cover  h-full w-full' src={bg} />
          </div>
          
          <div className='flex w-full  h-full '>
          <div className='flex flex-col mt-11 md:mt-20  lg:mt-2 mx-auto lg:flex-row'>
    <div className='  flex flex-col  mt-9 items-center text-center justify-center flex-1 px-4 lg:px-0'>
      <h1 className='text-[1.5rem] sm:text-[2rem] z-20 typewriter md:text-[3rem] text-focus-in tracking-widest text-[#fffdff] 
       shadow-2xl xl:text-[3.85rem] font-semibold leading-none mb-4'>
        {t('h1')}</h1>

      <p className=' mb-5 font-lora lg:mb-8 text-[.9rem] md:text-[1.5rem] z-20 text-[#efeeff]'>Elysium Luxury Properties
      </p>
      <Search />
      
      
    </div>
    
    
    
    </div>
    
          </div>
          
          </SwiperSlide>)
      })}
     </Swiper>
    )
  }
  

export default HeroSlider