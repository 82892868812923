import React, {useState, useEffect, useContext} from 'react';

import {RiHome5Line, RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import { useTranslation } from 'react-i18next';
import { Menu } from '@headlessui/react'

import {HouseContext} from './HouseContext'

const StatusDropdown = () => {
  const {status, setStatus, statuses} = useContext(HouseContext)

 const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false)
  return <Menu as='div' className='dropdown lg:min-w-[14em] relative' >
    <Menu.Button className='dropdown-btn w-full text-left' onClick={()=> setIsOpen(!isOpen) }>
      <RiHome5Line className='dropdown-icon-primary' />
      <div>
        <div className='text-[14px]  hover:text-[#413d42] leading-tight'>
          {status === 'გარიგების ტიპი (ნებისმიერი)' && t('dealType') }
          {status === 'იყიდება' && t('sale') }
          {status === 'ქირავდება' && t('rent') }
          </div>
        <div className='text-[10px]'>{t('dealType')}</div>
      </div>
        {
          isOpen ? (
            <RiArrowUpSLine 
            className='dropdown-icon-secondary' />
            ) : (
            <RiArrowDownSLine className='dropdown-icon-secondary' />
          )
        }
    </Menu.Button>

        <Menu.Items className='dropdown-menu'>
          {statuses.map((status,index) => {
            return (
              <Menu.Item onClick={() => setStatus(status)}
               className='cursor-pointer hover:text-violet-700 transition' as='li' key={index}>
               
          {status === 'იყიდება' && t('sale') }
          {status === 'ქირავდება' && t('rent') }
                </Menu.Item>
            )
          })}
        </Menu.Items>

    </Menu>;
};

export default StatusDropdown;
