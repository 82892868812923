import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
   
    debug: true,
    fallbackLng: 'ge',
    resources: {
        ge: {
            translation: {
                main: 'მთავარი',
                houses: 'სახლები',
                houses: 'სახლი',
                flats: 'ბინები',
                flat: 'ბინა',
                lands: 'მიწები',
                commercial: 'კომერციული',
                h1: 'იპოვეთ თქვენი ოცნების სახლი',
                footer: 'უძრავი ქონების სააგენტო',
                rights: 'ყველა უფლება დაცულია',
                ads: 'განცხადებები',
                property: 'უძრავი ქონება',
                propertyType: 'ქონების ტიპი (ნებისმიერი)',
                dealType: 'გარიგების ტიპი ',
                sale: 'იყიდება',
                rent: 'ქირავდება',
                location: 'ადგილმდებარეობა',
                tbilisi: 'თბილისი',
                saguramo: 'საგურამო',
                tsavkisi: 'წავკისი',
                balcony: 'აივანი',
                bathroom: 'სველი წერტილი',
                heating: 'გათბობა',
                parking:'პარკინგი',
                furniture: 'ავეჯი',
                elevator: 'ლიფტი',
                central: 'ცენტრალური',
                newRepaired: 'ახალი გარემონტებული'
            }
        },
        en: {
            translation: {
                main: 'Main',
                houses: 'Houses',
                house: 'House',
                flats: 'Flats',
                flat: 'Flat',
                lands: 'Lands',
                commercial: 'Commercial',
                h1: 'Find your dream house',
                footer: 'Real Estate Agency',
                rights: 'All Rights Reserved',
                ads: 'Find',
                property: 'Property',
                propertyType: 'Property Type',
                dealType: 'Type of deal',
                sale: 'For sale',
                rent: 'For rent',
                location: 'Location',
                tbilisi: 'Tbilisi',
                saguramo: 'Saguramo',
                tsavkisi: 'Tsavkisi',
                balcony: 'Balcony',
                bathroom: 'Bathroom',
                heating: 'Heating',
                parking:'Parking',
                furniture: 'Furniture',
                elevator: 'Elevator',
                central: 'Central',
                newRepaired: 'Newly Renovated'
            }
        }
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;