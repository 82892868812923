import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { BrowserRouter as Router } from 'react-router-dom'
import HouseContextProvider from './components/HouseContext'

import { HelmetProvider } from 'react-helmet-async'

import ScrollToTop from "./components/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HouseContextProvider>
    <Router>
      <HelmetProvider>
      <ScrollToTop />
      <App />
      </HelmetProvider>
    </Router>
  </HouseContextProvider>
);
