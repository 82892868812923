import React, { useContext, useState, useEffect,  } from 'react';

import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next';

import Logo from '../assets/img/logo.png';

import Image from '../assets/img/house-banner.png'

import Search from '../components/Search'

import {AiOutlineMenu,AiFillMail, AiOutlineClose, AiFillLinkedin} from 'react-icons/ai'

import { BsInstagram } from 'react-icons/bs'

import { HouseContext } from './HouseContext';



const lngs = {
  en: {nativeName: "English"},
  ge: {nativeName: "Georgian"}
}



const Header = () => {


  const { t, i18n } = useTranslation();

  const [header,setHeader] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 50 ? setHeader(true) : setHeader(false)
    })
  })



  const [navMobile, setNavMobile] = useState(false)
  const [bg, setBg] = useState(false)

 

  //scroll event
    useEffect(() => {
        window.addEventListener('scroll', () => {
            window.scrollY > 50 ? setBg(true) : setBg(false)
            
    },)
})

 const {showHouses,showFlats,setHouseType } = useContext(HouseContext);
console.log(Object.keys(lngs),'keyys')
  return <header id='head' className={`${bg ?
        "border-b-[1px] border-gray-300 shadow-md" : "border-gray-800 border-b-[1px]" 
       }
       ${header ? 'bg-transparent' : 'bg-red-300 shadow-lg'}
  bg-[#a79e9e] fixed w-full z-50 bg-blend-multiply transition-all duration-300 `}>
    <div className=" bg-[#6e9aeb17]  py-6 px-4  sm:px-12 md:px-12 lg:px-28
     w-full mx-auto flex  justify-between md:justify-center lg:justify-between items-center backdrop-blur 
      border-gray-400 backdrop-contrast-150 backdrop-brightness-125">
     <div className='flex items-center  gap-6'>
        <Link to='/' >
        <img className='w-44' src={Logo} alt='logo'/>
      </Link>
        
      </div>
      <div>
      {/*  {Object.keys(lngs).map((lng) => {
          return(
            <button 
          className='bg-white'
          type='submit' 
          onClick={() => i18n.changeLanguage(lng)}
          disabled={i18n.resolvedLanguage === lng}
          key={lng} >{lngs[lng].nativeName}</button>
          )
          
        })} */}
        {
        i18n.language === 'ge' && <img
        src={'/geo.png'}
        className='w-[2.5em] cursor-pointer'
        onClick={() => i18n.changeLanguage('en')}
        />
        }
        {
        i18n.language === 'en' && <img
        src={'/uk.png'}
        className='w-[2.5em] cursor-pointer'
        onClick={() => i18n.changeLanguage('ge')}
        />
        }
        
      </div>
      <ul className=' gap-2 hidden md:flex'>
        <li className='text-white text-[1rem] rounded-sm hover:bg-violet-600 hover:ease-in hover:duration-300 hover:transition-colors delay-75 cursor-pointer w-24 h-12 flex items-center justify-center font-[500]'>
          <a href="https://elisha.ge/">{t('main')}</a></li>
       
        <li onClick = {showHouses} className='text-white text-[1rem] rounded-sm hover:bg-violet-600 hover:ease-in hover:duration-300
        hover:transition-colors delay-100 w-24 h-12 flex items-center cursor-pointer justify-center font-[500]'>
              {t('houses')}       </li>
              <li onClick = {showFlats} className='text-white text-[1rem] rounded-sm hover:bg-violet-600 hover:ease-in hover:duration-300
        hover:transition-colors delay-100 w-24 h-12 flex items-center cursor-pointer justify-center font-[500]'>
              {t('flats')}       </li>
        <li className='text-white text-[1rem] rounded-sm hover:bg-violet-600 hover:ease-in hover:duration-300
        hover:transition-colors delay-100 w-24 h-12 flex items-center cursor-pointer justify-center font-[500]'>{t('lands')}</li>
      
        
      <a className='self-center' href='https://www.instagram.com/elysiumluxuryproperties' target='_blank'>
        <BsInstagram className='text-white  cursor-pointer hover:text-violet-600' /></a>
      <a className='self-center' href='https://www.linkedin.com/in/tinatin-dgebuadze-tikadgebuadze-367a17b3/' target='_blank'>
        <AiFillLinkedin  className='text-white text-[1.25rem] cursor-pointer hover:text-violet-600 self-center' />
        </a>
      <a className='self-center' href="mailto:tikadgebuadze@yahoo.com">
        < AiFillMail className='text-white text-[1.25rem] cursor-pointer hover:text-violet-600 self-center'/>
      </a>
      <p className='text-white hidden lg:flex text-[1rem] flex-shrink-0 font-number cursor-pointer hover:text-white self-center'>+995 598991818</p>

      </ul>
      {/*Button*/}
      
      <div onClick={() => setNavMobile(!navMobile)} className='md:hidden '>
      {!navMobile && <AiOutlineMenu className='text-slate-200 bg-slate-500 bg-opacity-70 rounded-2xl w-12 h-8 cursor-pointer' />}
      
      {navMobile && <AiOutlineClose className='text-slate-200 bg-slate-500 bg-opacity-70 rounded-2xl w-12 h-8 cursor-pointer' />}
      </div>
      <div className={`${
            navMobile ? 'h-[190px]' : 'max-h-0'
          } fixed w-full bg-violet-700  shadow-lg top-[81px] left-0 h-full overflow-hidden transition-all`}
        >
               <nav className='h-full '>
      <ul className='flex flex-col leading-8 tracking-widest justify-center items-center h-full '>
        <li className='text-white text-[1.1rem] active:bg-slate-300 active:text-slate-900 font-[500]'>
          <Link to='/'>{t('main')}</Link></li>
        <li onClick = {showHouses} className='text-white text-[1.1rem] 
       cursor-pointer active:bg-slate-300 active:text-slate-900 font-[500]'>{t('houses')}</li>
        <li className='text-white text-[1.1rem] 
       cursor-pointer active:bg-slate-300 active:text-slate-900 font-[500]'>{t('lands')}</li>
        
      </ul>
    </nav>
            </div>
    </div>
    
    </header>;
};

export default Header;
