// import agents images

import Agent2 from "./assets/img/agents/agent2.png";

export const housesData = [
  {
    id: 1,
    type: "სახლი",
    status: "იყიდება",
    namege: "სახლი დიდ დიღომში",
    nameen: "House in Did Dighomi",
    descriptionge:
      "იყიდება 2 სართულიანი სახლი ეზოთი დიდ დიღომში, მაღალ ხარისხიანი რემონტით სახლი არის 322 კვ.მ, ეზო 500 კვ.მ თექნიკით და ავეჯით. 7 ოთახი, 4 საძინებელი, 2 სველი წერტილი.",
    descriptionen:
      "A 2-floor house with a yard is for sale in Didi Dighomi, the house is 322 sq.m. With high-quality renovation, the yard is 500 sq.m. with equipment and furniture. 7 rooms, 4 bedrooms, 2 bathrooms.",
    image: "https://i.ibb.co/x574mp2/4.jpg",
    imageLg: "https://i.ibb.co/x574mp2/4.jpg",
    country: "თბილისი",
    addressge: "თბილისი, დიდ დიღომი",
    addressen: "Tbilisi, Did Dighomi",
    bedrooms: "7",
    bathrooms: "2",
    surface: "322 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "2",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: false,
    },
    price: "480 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/x2HyBkH/3.jpg" },
      { url: "https://i.ibb.co/x574mp2/4.jpg" },
      { url: "https://i.ibb.co/NSh2yLH/5.jpg" },
      { url: "https://i.ibb.co/LzcsR0P/6.jpg" },
      { url: "https://i.ibb.co/jwCx0xj/7.jpg" },
      { url: "https://i.ibb.co/hY8Gq38/8.jpg" },
      { url: "https://i.ibb.co/NKY1MYs/9.jpg" },
      { url: "https://i.ibb.co/vcXFFq5/10.jpg" },
      { url: "https://i.ibb.co/LgjZxpf/11.jpg" },
      { url: "https://i.ibb.co/TR1qhtq/12.jpg" },
      { url: "https://i.ibb.co/P4CHhG9/13.jpg" },
      { url: "https://i.ibb.co/6PgHwyW/14.jpg" },
      {
        url: "https://i.ibb.co/nmB8ySj/Whats-App-Image-2023-03-21-at-16-44-14.jpg",
      },
      {
        url: "https://i.ibb.co/9gY5JNz/Whats-App-Image-2023-03-21-at-16-45-16.jpg",
      },
    ],
  },
  {
    id: 2,
    type: "ბინა",
    status: "იყიდება",
    namege: "ბინა ისანში",
    nameen: "Flat in Isani",
    descriptionge:
      "იყიდება ბინა ისანში საუკეთესო ადგილას, მეტროსთან ახლოს ახალაშენებულ კორპუსში, კორპუსი აშენებულია ენერგოეფექტური მასალით, ფუნქციონირებს ლიფტი, დაცვა, სადარბაზოები მოწესრიგებული, ბინა არის უცხოვრებელი, საუკეთესო ხედებით, ახალგარემონტებული, მაღალი ხარისხის მასალებით, საუკეთესო ხარისხის ავეჯით (ლაბორატორიის) და ჩაშენებული ტექნიკით (Electrolux), (სარეცხი მანქანა, ჭურჭლის სარეცხი მანქანა, მაცივარი) ცენტრალური გათბობის ქვაბით, დიდი სამსუნგის ტელევიზორით, ჩართულია ინტერნეტი და ტელევიზია.",
    descriptionen:
      "An apartment is for sale in the best location in Isani, in a newly built building near the metro, the building is built with energy-efficient materials, there is an elevator, security, the entrances are in order, the apartment is uninhabited, with the best views, newly renovated, with high-quality materials, the best quality furniture (laboratory) and built-in appliances (Electrolux). (Washing machine, dishwasher, fridge) with central heating boiler, large Samsung TV, internet and TV enabled.",
    image: "https://i.ibb.co/1rNMwMH/5.jpg",
    imageLg: "https://i.ibb.co/1rNMwMH/5.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ისანი",
    addressen: "თბილისი, ისანი",
    bedrooms: "2",
    bathrooms: "1",
    surface: "63 კვ.მ",
    details: {
      repair: "ევრო რემონტი",
      balcony: true,
      toilet: "1",
      heat: "ცენტრალური",
      parking: false,
      furniture: true,
      elevator: true,
    },
    price: "115 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/QnN8nW6/1.jpg" },
      { url: "https://i.ibb.co/Ctd8CW9/3.jpg" },
      { url: "https://i.ibb.co/PhrL1s2/4.jpg" },
      { url: "https://i.ibb.co/1rNMwMH/5.jpg" },
      { url: "https://i.ibb.co/dk4438w/6.jpg" },
      { url: "https://i.ibb.co/b2NjVgb/7.jpg" },
      { url: "https://i.ibb.co/w6D0Txr/8.jpg" },
      { url: "https://i.ibb.co/rv4fsmg/9.jpg" },
      { url: "https://i.ibb.co/gmS9DmW/10.jpg" },
      { url: "https://i.ibb.co/JvSZnxZ/11.jpg" },
      { url: "https://i.ibb.co/Bc0jjgr/12.jpg" },
      { url: "https://i.ibb.co/dMFzdtj/14.jpg" },
      { url: "https://i.ibb.co/m5FPx1x/15.jpg" },
      { url: "https://i.ibb.co/G09YT0y/19.jpg" },
      { url: "https://i.ibb.co/9rLk26f/20.jpg" },
      { url: "https://i.ibb.co/bstsvyT/21.jpg" },
      { url: "https://i.ibb.co/Kj77VTQ/22.jpg" },
    ],
  },
  {
    id: 3,
    type: "ბინა",
    status: "იყიდება",
    namege: "2 ოთახიანი ბინა საბურთალოზე",
    nameen: "2 room apartment in Saburtalo",
    descriptionge:
      "იყიდება მაღალი ხარისხის რემონტით დელუქსის სახლში, იდეალურია გასაქირავებლად, 24/7 დაცვით",
    descriptionen:
      "For sale in a high quality renovated deluxe house, ideal for rental, with 24/7 security",
    image: "https://i.ibb.co/4TPbGHq/4.jpg",
    imageLg: "https://i.ibb.co/4TPbGHq/4.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ალექსიძის ქუჩა, საბურთალო",
    addressen: "Tbilisi, Aleksidze Street, Saburtalo",
    bedrooms: "2",
    bathrooms: "1",
    surface: "49 კვ.მ",
    details: {
      repair: "ევრო რემონტი",
      balcony: true,
      toilet: "1",
      heat: "ცენტრალური",
      parking: false,
      furniture: true,
      elevator: true,
    },
    price: "188 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/z27FymW/1.jpg" },
      { url: "https://i.ibb.co/VCVMZB7/2.jpg" },
      { url: "https://i.ibb.co/MZDN4x7/3.jpg" },
      { url: "https://i.ibb.co/4TPbGHq/4.jpg" },
      { url: "https://i.ibb.co/Hq3zg6g/5.jpg" },
      { url: "https://i.ibb.co/ZL6KLm7/6.jpg" },
      { url: "https://i.ibb.co/nzzBZ4g/7.jpg" },
      { url: "https://i.ibb.co/cDLK3Ct/8.jpg" },
      { url: "https://i.ibb.co/sQGYNNh/9.jpg" },
      { url: "https://i.ibb.co/1zfKpHS/11.jpg" },
      { url: "https://i.ibb.co/p3cgLYD/12.jpg" },
      { url: "https://i.ibb.co/yfYd1sJ/13.jpg" },
      { url: "https://i.ibb.co/vQycjRS/14.jpg" },
      { url: "https://i.ibb.co/P6RP7S5/15.jpg" },
    ],
  },
  {
    id: 4,
    type: "ბინა",
    status: "იყიდება",
    namege: "2 ოთახიანი ბინა საბურთალოზე",
    nameen: "2 room apartment in Saburtalo",
    descriptionge:
      "იყიდება 2 ოთახიანი ბინა საბურთალოზე, ქავთარაძის ქუჩა არქის აშენებულ კორპუსში, 55 კვადრატი 7-ე სართული სულ 12 სართული, ახალი გარემონტებული, უცხოვრებელი ჩაშენებული სამზარეულო.",
    descriptionen:
      "2-room apartment for sale on Saburtalo, Kavtaradze Street in the building built by Arch, 55 square meters, 7th floor, 12 floors in total, newly renovated, uninhabited built-in kitchen.",
    image: "https://i.ibb.co/XD5FTCQ/5.jpg",
    imageLg: "https://i.ibb.co/XD5FTCQ/5.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ქავთარაძის ქუჩა, საბურთალო",
    addressen: "Tbilisi, Kavtaradze Street, Saburtalo",
    bedrooms: "2",
    bathrooms: "1",
    surface: "49 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "1",
      heat: "ცენტრალური",
      parking: false,
      furniture: false,
      elevator: true,
    },
    price: "105 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/Mp5wFy4/1.jpg" },
      { url: "https://i.ibb.co/NSdt4tt/2.jpg" },
      { url: "https://i.ibb.co/kqV76h6/3.jpg" },
      { url: "https://i.ibb.co/PDKkVHJ/4.jpg" },
      { url: "https://i.ibb.co/XD5FTCQ/5.jpg" },
      { url: "https://i.ibb.co/872gzSB/6.jpg" },
      { url: "https://i.ibb.co/2FxvCCQ/7.jpg" },
      { url: "https://i.ibb.co/BGK4cGH/8.jpg" },
      { url: "https://i.ibb.co/xfC5wZY/9.jpg" },
      { url: "https://i.ibb.co/Smj5Lkp/10.jpg" },
      { url: "https://i.ibb.co/Lh21gtZ/11.jpg" },
      { url: "https://i.ibb.co/SVJFWRF/12.jpg" },
      { url: "https://i.ibb.co/C2gc976/13.jpg" },
    ],
  },
  {
    id: 5,
    type: "სახლი",
    status: "ქირავდება",
    namege: "ქირავდება სახლი კრწანისში",
    nameen: "House in Krtsanisi For Rent",
    descriptionge:
      "ქირავდება ულამაზესი კერძო სახლი 800 კვმ. მიწა 1800 ვკმ. დიდი გამწვანებული ეზოთი აუზით,საჩოგბურთო კორტებით, სატრენაჟორო დარბაზით",
    descriptionen:
      "Beautiful private house for rent, 800 sq.m. Land 1800 sq.m. With a large green yard with a pool, tennis courts, gym",
    image: "https://i.ibb.co/3S40PBC/1.jpg",
    imageLg: "https://i.ibb.co/9pp8kRC/2.jpg",
    country: "თბილისი",
    addressge: "თბილისი, კრწანისი, მთაწმინდის რაიონი,",
    addressen: "თბილისი, კრწანისი, მთაწმინდის რაიონი,",
    bedrooms: "5",
    bathrooms: "5",
    surface: "800 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "3",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: false,
    },
    price: "20,000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/3S40PBC/1.jpg" },
      { url: "https://i.ibb.co/9pp8kRC/2.jpg" },
      { url: "https://i.ibb.co/4Yk1cWQ/3.jpg" },
      { url: "https://i.ibb.co/3WPf7M9/4.jpg" },
      { url: "https://i.ibb.co/FsPM6Xb/5.jpg" },
      { url: "https://i.ibb.co/4MRgJ3h/6.jpg" },
      { url: "https://i.ibb.co/PYkcw4s/7.jpg" },
      { url: "https://i.ibb.co/zZCJC6d/8.jpg" },
      { url: "https://i.ibb.co/23dyZmK/9.jpg" },
      { url: "https://i.ibb.co/D5KfjFX/10.jpg" },
      { url: "https://i.ibb.co/TmCSkJw/11.jpg" },
      { url: "https://i.ibb.co/7GkDm0k/12.jpg" },
      { url: "https://i.ibb.co/yfXZWHb/13.jpg" },
    ],
  },
  {
    id: 6,
    type: "სახლი",
    status: "იყიდება",
    namege: "იყიდება სახლი ქოშიგორაზე",
    nameen: "House on Koshigora for Sale",
    descriptionge:
      "იყიდება ულამაზესი 4 სართულიანი სახლი(800კვმ),  მიწა(2000კვმ) ქოშიგორაზე, წყნარ ეკოლოგიურათ სუფთა ადგილას, ყველა ოთახიდან პანორამული ხედით ახალი აშენებულია უცხოვრებელი, ღია საცურაო აუზი, საუნა, გასართობი სივრცით, დამონტაჟებულია 13 კამერა და დაცვის სისტემა",
    descriptionen:
      "A beautiful 4-storey house (800 sq.m.), land (2000 sq.m.) is for sale in Koshigora, in a quiet, ecologically clean place, with a panoramic view from all rooms.",
    image: "https://i.ibb.co/J3TGQ9C/1.jpg",
    imageLg: "https://i.ibb.co/pvndywD/8.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ქოშიგორა",
    addressen: "Tbilisi, Koshigora",
    bedrooms: "7",
    bathrooms: "7",
    surface: "600 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "7",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: false,
    },
    price: "1 600 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/J3TGQ9C/1.jpg" },
      { url: "https://i.ibb.co/X4W6CMD/2.jpg" },
      { url: "https://i.ibb.co/XLjPWj7/3.jpg" },
      { url: "https://i.ibb.co/k33tzLj/4.jpg" },
      { url: "https://i.ibb.co/K5CYL2P/5.jpg" },
      { url: "https://i.ibb.co/pjZbH7V/6.jpg" },
      { url: "https://i.ibb.co/F3LjRHz/7.jpg" },
      { url: "https://i.ibb.co/pvndywD/8.jpg" },
      { url: "https://i.ibb.co/PCNpNVg/9.jpg" },
      { url: "https://i.ibb.co/jrJf9DZ/10.jpg" },
      { url: "https://i.ibb.co/zSNFt2t/11.jpg" },
      { url: "https://i.ibb.co/k6dw212/12.jpg" },
      { url: "https://i.ibb.co/Mnkbz8V/13.jpg" },
      { url: "https://i.ibb.co/9WPQ3Xh/14.jpg" },
      { url: "https://i.ibb.co/XWNM2HT/15.jpg" },
      { url: "https://i.ibb.co/Bfh6KXV/16.jpg" },
      { url: "https://i.ibb.co/G9DS3VL/17.jpg" },
      { url: "https://i.ibb.co/xjB2hCM/18.jpg" },
      { url: "https://i.ibb.co/LRjfkNh/19.jpg" },
      { url: "https://i.ibb.co/TMJ0Dq1/20.jpg" },
      { url: "https://i.ibb.co/HGzZQsf/21.jpg" },
    ],
  },
  {
    id: 7,
    type: "სახლი",
    status: "იყიდება",
    namege: "იყიდება სახლი ოქროყანაში",
    nameen: "House in Oqrokana for sale",
    descriptionge:
      "იყიდება 2 სართულიანი სახლი(265კვმ) ოქროყანაში. 600კვმ მიწა. სახლი არის ავეჯით და ტექნიკით. კეთილმოწყობილი ეზოთი. ვერანდა თბილისის ხედით. 4 საძინებელი, 3 სველი წერითილი. მისაღები ბუხარით, სამზარეულო , 2 საგარდერობე ოთახი. ",
    descriptionen:
      "A 2-floor house (265 square meters) is for sale in Okrokana. 600 square meters of land. The house is furnished and equipped. with a well-furnished yard. Veranda with a view of Tbilisi. 4 bedrooms, 3 bathrooms. Living room with fireplace, kitchen, 2 wardrobe rooms.",
    image: "https://i.ibb.co/svnVzzC/27.jpg",
    imageLg: "https://i.ibb.co/svnVzzC/27.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ოქროყანა",
    addressen: "Tbilisi, Oqrokana",
    bedrooms: "6",
    bathrooms: "3",
    surface: "265 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "3",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: false,
    },
    price: "620 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/PDjj26P/1.jpg" },
      { url: "https://i.ibb.co/RQf5n2S/2.jpg" },
      { url: "https://i.ibb.co/PgN8LPk/3.jpg" },
      { url: "https://i.ibb.co/dfbM0VQ/4.jpg" },
      { url: "https://i.ibb.co/Gcvr4mG/5.jpg" },
      { url: "https://i.ibb.co/kxrPLVF/6.jpg" },
      { url: "https://i.ibb.co/DftDc2z/7.jpg" },
      { url: "https://i.ibb.co/mtbP8S8/8.jpg" },
      { url: "https://i.ibb.co/4Jrxp7B/9.jpg" },
      { url: "https://i.ibb.co/dLTvNfZ/10.jpg" },
      { url: "https://i.ibb.co/SN3fMdK/11.jpg" },
      { url: "https://i.ibb.co/Y05nzZf/12.jpg" },
      { url: "https://i.ibb.co/g7YTNTc/13.jpg" },
      { url: "https://i.ibb.co/FzcdxbJ/14.jpg" },
      { url: "https://i.ibb.co/Y2Bj0CV/15.jpg" },
      { url: "https://i.ibb.co/6n5mKX6/16.jpg" },
      { url: "https://i.ibb.co/861rxcs/17.jpg" },
      { url: "https://i.ibb.co/mTZGGn4/18.jpg" },
      { url: "https://i.ibb.co/S0bSyfv/19.jpg" },
      { url: "https://i.ibb.co/dWPvV04/20.jpg" },
      { url: "https://i.ibb.co/svgX5jK/21.jpg" },
      { url: "https://i.ibb.co/VvLDCFZ/22.jpg" },
      { url: "https://i.ibb.co/xgv2TgR/23.jpg" },
      { url: "https://i.ibb.co/x58mbjs/24.jpg" },
      { url: "https://i.ibb.co/m0GjpmC/25.jpg" },
      { url: "https://i.ibb.co/vQWK2mD/26.jpg" },
      { url: "https://i.ibb.co/kgjSvvJ/27.jpg" },
      { url: "https://i.ibb.co/B2xgQF3/28.jpg" },
      { url: "https://i.ibb.co/S5pP0z1/29.jpg" },
      { url: "https://i.ibb.co/tJLsycZ/30.jpg" },
      { url: "https://i.ibb.co/3p41vwt/31.jpg" },
    ],
  },
  {
    id: 8,
    type: "სახლი",
    status: "იყიდება",
    namege: "იყიდება სახლი დიღომში",
    nameen: "House in Dighomi for sale",
    descriptionge:
      "დიღომის 7ში, თბილისში პრესტიჟულ დასახლებაში, იყიდება ჰაიტექ დიზაინ ვილა(500კვმ), მიწა(1150კვმ) კონსტრუქტორის მიერ გათვლის 9.5 ბალიან მიწისძვრაზე. 5 მანქანის პარკინგით, 1150 კვმ გამწვანება, მწვანე ლეილანდების ღობით იზოლირებული ეზოთი, სარწყავი სისტემით, დიდი ბასეინით და დასაჯდომი სივრცეთი. ვილა 2 სართულიანია. 1 სართულზე დიდი მისაღები ოთახი ბუხრით 50კვმ. დიდი იზოლირებული სამზარეულოთი 50კვმ. 1 საძინებელი საგარდერობე ოთახით, კაბინეტი, დიდი აბაზანა ჯაკუზით. სახლი აშენებულია 2019 წელს. რჩება ავეჯი და ტექნიკა  ",
    descriptionen:
      "Hi-tech design villa (500 sq.m.), land (1150 sq.m.) is for sale at Digomi 7, a prestigious settlement in Tbilisi. With parking for 5 cars, 1150 sq.m. of landscaping, fenced yard with green Leylands, irrigation system, large pool and seating area. The villa has 2 floors. On the 1st floor, a large living room with a fireplace, 50 square meters. With a large isolated kitchen of 50 sq.m. 1 bedroom with dressing room, cabinet, large bathroom with jacuzzi. The house was built in 2019. Furniture and appliances remain",
    image: "https://i.ibb.co/hcbhDfg/3.jpg",
    imageLg: "https://i.ibb.co/hcbhDfg/3.jpg",
    country: "თბილისი",
    addressge: "თბილისი, დიღომი",
    addressen: "Tbilisi, Dighomi",
    bedrooms: "7",
    bathrooms: "5",
    surface: "500 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "5",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: false,
    },
    price: "1 300 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/bv0TD44/1.jpg" },
      { url: "https://i.ibb.co/p13dbHW/2.jpg" },
      { url: "https://i.ibb.co/hcbhDfg/3.jpg" },
      { url: "https://i.ibb.co/vvKb1Pq/4.jpg" },
      { url: "https://i.ibb.co/mvqq1QV/5.jpg" },
      { url: "https://i.ibb.co/ChKhfR8/6.jpg" },
      { url: "https://i.ibb.co/DLVz90N/7.jpg" },
      { url: "https://i.ibb.co/k6PHBgL/8.jpg" },
      { url: "https://i.ibb.co/p0s44gT/9.jpg" },
      { url: "https://i.ibb.co/bNPmqDx/10.jpg" },
      { url: "https://i.ibb.co/qxCw4jJ/11.jpg" },
      { url: "https://i.ibb.co/856HH7D/12.jpg" },
      { url: "https://i.ibb.co/984w16k/13.jpg" },
      { url: "https://i.ibb.co/pXJBgBB/14.jpg" },
      { url: "https://i.ibb.co/2Sb3Fjf/15.jpg" },
      { url: "https://i.ibb.co/yyXsYYf/16.jpg" },
      { url: "https://i.ibb.co/yn2TPbk/17.jpg" },
      { url: "https://i.ibb.co/bFcLwzM/18.jpg" },
      { url: "https://i.ibb.co/x1L2xhL/19.jpg" },
      { url: "https://i.ibb.co/7nsLJVQ/20.jpg" },
      { url: "https://i.ibb.co/ZLdxjf7/21.jpg" },
      { url: "https://i.ibb.co/MDrwySP/22.jpg" },
    ],
  },
  {
    id: 9,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება ბინა ისანი, ჰოლბრუკის ქუჩა",
    nameen: "Apartment in Isani, Holbruck Street",
    descriptionge:
      "იყიდება 3 ოთახიანი(86კვმ) ბინა ისანში ჰოლბრუკის ქუჩაზე ახალ სახლში,  ახალი  მაღალი ხარისხის ევრორემონტით ავეჯით და ტექნიკით, უცხოვრებელი",
    descriptionen:
      "3-room (86 sq.m.) apartment for sale in a new house on Holbrook Street in Isan, with new high-quality European renovation with furniture and appliances, uninhabited",
    image: "https://i.ibb.co/c3M3xqR/2.jpg",
    imageLg: "https://i.ibb.co/c3M3xqR/2.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ისანი, ჰოლბრუკის ქუჩა",
    addressen: "Isani, Holbruck Street",
    bedrooms: "3",
    bathrooms: "1",
    surface: "86 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "1",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: true,
    },
    price: "155 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/sbhsNtB/1.jpg" },
      { url: "https://i.ibb.co/c3M3xqR/2.jpg" },
      { url: "https://i.ibb.co/51jgmCF/3.jpg" },
      { url: "https://i.ibb.co/7bNXQm6/4.jpg" },
      { url: "https://i.ibb.co/gmv8mQv/5.jpg" },
      { url: "https://i.ibb.co/t3gn6C4/6.jpg" },
      { url: "https://i.ibb.co/d0m83tK/7.jpg" },
      { url: "https://i.ibb.co/rxRjgMz/8.jpg" },
      { url: "https://i.ibb.co/Y3rxQM1/9.jpg" },
      { url: "https://i.ibb.co/VV2V5vp/10.jpg" },
      { url: "https://i.ibb.co/5nKdY4n/11.jpg" },
      { url: "https://i.ibb.co/Ln0rcCg/12.jpg" },
      { url: "https://i.ibb.co/5KdJ1sT/13.jpg" },
      { url: "https://i.ibb.co/M993Qrg/14.jpg" },
      { url: "https://i.ibb.co/wZHPgXh/15.jpg" },
      { url: "https://i.ibb.co/3fFRNsX/16.jpg" },
    ],
  },
  {
    id: 10,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება ბინა ვაკეში, ამირეჯიბის ქუჩა",
    nameen: "Apartment in Vake, Amirejibi Street",
    descriptionge: "იყიდება გარემონტებული 2 ოთახიანი ბინა ავეჯით 66კვმ",
    descriptionen:
      "Renovated 2-room apartment with furniture, 66 sq.m., for sale",
    image:
      "https://i.ibb.co/Gv31X39/Whats-App-Image-2023-05-23-at-23-08-53.jpg",
    imageLg:
      "https://i.ibb.co/Gv31X39/Whats-App-Image-2023-05-23-at-23-08-53.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ვაკე, ამირეჯიბის ქუჩა",
    addressen: "Vake, Amirejibi Street, Tbilisi",
    bedrooms: "2",
    bathrooms: "1",
    surface: "66 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "1",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: true,
    },
    price: "145 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/5Kv63zT/3.jpg" },
      { url: "https://i.ibb.co/bH2pZQ6/4.jpg" },
      { url: "https://i.ibb.co/72MbPHR/5.jpg" },
      { url: "https://i.ibb.co/0qKgw4m/7.jpg" },
      { url: "https://i.ibb.co/vq2376m/8.jpg" },
      { url: "https://i.ibb.co/ZdyfTHr/9.jpg" },
      { url: "https://i.ibb.co/sg83jsX/10.jpg" },
      { url: "https://i.ibb.co/Fny0pHn/11.jpg" },
      {
        url: "https://i.ibb.co/SX7127c/Whats-App-Image-2023-05-23-at-23-08-53.jpg",
      },
    ],
  },
  {
    id: 11,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება საბურთალოზე, კარტოზიას ქუჩა",
    nameen: "Apartment in Saburtalo, Kartozia Street",
    descriptionge:
      "იყიდება 3 ოთახიანი გარემონტებული ბინა 86კვმ. ავეჯით და ტექნიკით. 22/28 სართული",
    descriptionen:
      "3-room renovated apartment of 86 square meters for sale. with furniture and equipment. 22/28 floor",
    image: "https://i.ibb.co/6YWhJW6/1.jpg",
    imageLg: "https://i.ibb.co/6YWhJW6/1.jpg",
    country: "თბილისი",
    addressge: "თბილისი, საბურთალო, კარტოზიას ქუჩა",
    addressen: "Tbilisi,Saburtalo, Kartozia Street",
    bedrooms: "3",
    bathrooms: "1",
    surface: "86 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "1",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: true,
    },
    price: "185 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/6YWhJW6/1.jpg" },
      { url: "https://i.ibb.co/Sy3g6JP/2.jpg" },
      { url: "https://i.ibb.co/Q9qG0vX/3.jpg" },
      { url: "https://i.ibb.co/K6gy1TV/4.jpg" },
      { url: "https://i.ibb.co/XY60Hvp/5.jpg" },
      { url: "https://i.ibb.co/yQDBTRW/6.jpg" },
      { url: "https://i.ibb.co/njqJhrD/7.jpg" },
      { url: "https://i.ibb.co/C71zmty/8.jpg" },
      { url: "https://i.ibb.co/4WZC0mk/9.jpg" },
      { url: "https://i.ibb.co/YRbBYF8/10.jpg" },
      { url: "https://i.ibb.co/dfHQNs5/11.jpg" },
      { url: "https://i.ibb.co/2MG76Fw/12.jpg" },
      { url: "https://i.ibb.co/ctRsTzL/13.jpg" },
      { url: "https://i.ibb.co/4g1pZXn/14.jpg" },
      { url: "https://i.ibb.co/2Yw4wym/15.jpg" },
    ],
  },
  {
    id: 12,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება ვაკეში, თამარაშვილის ქუჩა",
    nameen: "Apartment in Vake for sale, Tamarashvili Strt",
    descriptionge:
      "იყიდება ახალაშენებულ კორპუსში 3 ოთახიანი, დიზაინერული რემონტით ძვირადღირებული მასალით.100კვმ. 25/28 სართული. კერამოგრანიტის იატაკი, მიუნხენის შელესილი შპალერი. კარები ალუმინის ჩარჩოთი, ფარუოლი საკეტები ხმაურის იზოლაციით. შავი სარკისებრი ჭერი, ჩაშენებული შავი აკრილის სამზარეულო BLUM მექანიზმებით, ტექნიკით, ინდუქციური ქურა, ჭურჭლის სარეცხი მანქანა 12 პერსონაზე. ელექტრო ღუმელი მიკროტალღური, ჩაშენებული მაცივარი, საძინებლის კომპლექტი, მაგიდები სკამები სავარძლები. თბილისის პანორამული ხედით. ",
    descriptionen:
      "For sale in a newly built building with 3 rooms, designer renovation with expensive materials. 100 sq.m. 25/28 floor. Ceramic granite floors, Munich plaster wallpaper. Doors with aluminum frame, side locks with noise isolation. Black mirrored ceiling, built-in black acrylic kitchen with BLUM appliances, induction cooker, dishwasher for 12 persons. Electric oven, microwave, built-in refrigerator, bedroom set, tables, chairs, armchairs. With a panoramic view of Tbilisi.",
    image: "https://i.ibb.co/jk0dz79/10.jpg",
    imageLg: "https://i.ibb.co/jk0dz79/10.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ვაკე, თამარაშვილის ქუჩა",
    addressen: "Tbilisi, Vake Tamarashvili Strt",
    bedrooms: "3",
    bathrooms: "2",
    surface: "100 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "2",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: true,
    },
    price: "320 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/MDbRv22/1.jpg" },
      { url: "https://i.ibb.co/5MVp44Z/2.jpg" },
      { url: "https://i.ibb.co/0QM58KK/3.jpg" },
      { url: "https://i.ibb.co/ZJ5h1KV/4.jpg" },
      { url: "https://i.ibb.co/Sm64fVm/5.jpg" },
      { url: "https://i.ibb.co/w0fYqcW/6.jpg" },
      { url: "https://i.ibb.co/FgGTDVb/7.jpg" },
      { url: "https://i.ibb.co/FzBnyTG/8.jpg" },
      { url: "https://i.ibb.co/xfN0T3G/9.jpg" },
      { url: "https://i.ibb.co/jk0dz79/10.jpg" },
      { url: "https://i.ibb.co/0rQXQhJ/11.jpg" },
      { url: "https://i.ibb.co/MG2mp71/12.jpg" },
      { url: "https://i.ibb.co/DY4FK1T/13.jpg" },
      { url: "https://i.ibb.co/QQ49bpG/14.jpg" },
      { url: "https://i.ibb.co/CKJWHCm/15.jpg" },
      { url: "https://i.ibb.co/ZKXZJ0b/16.jpg" },
      { url: "https://i.ibb.co/17mQTVM/17.jpg" },
      { url: "https://i.ibb.co/Q9QwtcP/18.jpg" },
      { url: "https://i.ibb.co/1RzKNzX/19.jpg" },
      { url: "https://i.ibb.co/gW13DQN/20.jpg" },
      { url: "https://i.ibb.co/zF1JTNh/21.jpg" },
      { url: "https://i.ibb.co/bWgsFKy/22.jpg" },
      { url: "https://i.ibb.co/j6NGnsG/23.jpg" },
    ],
  },
  {
    id: 13,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება ვაკეში, თამარაშვილის ქუჩა",
    nameen: "Apartment in Vake for sale, Tamarashvili strt",
    descriptionge:
      "იყიდება ბინა თამარაშვილზე ახალ სახლში 204 კვ ფასი 570000$ ევრორემონტო ავეჯით ტექნიკით უცხოვრებელი, 4 ოთახი , 3 სვ წერტილი 17/24 სართულზე",
    descriptionen:
      "Apartment for sale in new house in Tamarashvili, 204 square meters, price $570,000, euro-renovation, furniture, appliances, uninhabited, 4 rooms, 3 square meters, 17/24 floor",
    image: "https://i.ibb.co/vXSYvX9/1.jpg",
    imageLg: "https://i.ibb.co/vXSYvX9/1.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ვაკე, თამარაშვილის ქუჩა",
    addressen: "Tbilisi, Vake, Tamarashvili strt",
    bedrooms: "4",
    bathrooms: "3",
    surface: "204 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "3",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: true,
    },
    price: "545 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/vXSYvX9/1.jpg" },
      { url: "https://i.ibb.co/yQ1LBT5/2.jpg" },
      { url: "https://i.ibb.co/wRdHLKh/3.jpg" },
      { url: "https://i.ibb.co/fkfhvcb/4.jpg" },
      { url: "https://i.ibb.co/QCtJqHd/5.jpg" },
      { url: "https://i.ibb.co/k1QfSfg/6.jpg" },
      { url: "https://i.ibb.co/WpfKjGw/7.jpg" },
      { url: "https://i.ibb.co/1q6hjtp/8.jpg" },
      { url: "https://i.ibb.co/Jnk5dZ2/9.jpg" },
      { url: "https://i.ibb.co/j6YDzTL/10.jpg" },
      { url: "https://i.ibb.co/rwtYM0x/11.jpg" },
      { url: "https://i.ibb.co/Hp2bcZK/12.jpg" },
    ],
  },
  {
    id: 14,
    type: "ბინა",
    status: "ქირავდება",
    namege: "ქირავდება ვერა, ფიქრის გორა",
    nameen: "Apartment in Vera, Fiqris Gora, For Rent",
    descriptionge:
      "ქირავდება 185კვმ. ბინა ვერაზე, ფიქრის გორაზე. 3 ოთახი, 3 სველი წერტილი, აივანი. აღჭურვილია საუკეთესო ტექნიკით და ავეჯით. 5 სართული",
    descriptionen:
      "185 square meters for rent. Apartment on Vera, on Pikris Hill. 3 rooms, 3 bathrooms, balcony. It is equipped with the best equipment and furniture. 5 floors",
    image: "https://i.ibb.co/GWwVGVS/1.jpg",
    imageLg: "https://i.ibb.co/GWwVGVS/1.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ვერა, ფიქრის გორა",
    addressen: "Tbilisi, Vera, Fiqris Gora",
    bedrooms: "3",
    bathrooms: "3",
    surface: "185 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "3",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: true,
    },
    price: "2500",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/GWwVGVS/1.jpg" },
      { url: "https://i.ibb.co/ZxVf9ny/2.jpg" },
      { url: "https://i.ibb.co/vdN3hHQ/3.jpg" },
      { url: "https://i.ibb.co/jJ4G5mr/4.jpg" },
      { url: "https://i.ibb.co/DWWvBhv/5.jpg" },
      { url: "https://i.ibb.co/PjddS56/6.jpg" },
      { url: "https://i.ibb.co/vcSvXWP/7.jpg" },
      { url: "https://i.ibb.co/ZV61tQm/8.jpg" },
      { url: "https://i.ibb.co/0Vr5GSV/9.jpg" },
      { url: "https://i.ibb.co/bmdPX3J/10.jpg" },
      { url: "https://i.ibb.co/wQwbz43/11.jpg" },
      { url: "https://i.ibb.co/w6GbTLG/12.jpg" },
      { url: "https://i.ibb.co/mF51Q39/13.jpg" },
      { url: "https://i.ibb.co/mGnjYwx/14.jpg" },
      { url: "https://i.ibb.co/D1YtYnj/15.jpg" },
      { url: "https://i.ibb.co/Bqq7zb8/16.jpg" },
      { url: "https://i.ibb.co/wSvxQwd/17.jpg" },
      { url: "https://i.ibb.co/t8Zp1sQ/18.jpg" },
      { url: "https://i.ibb.co/KXMzyVs/19.jpg" },
      { url: "https://i.ibb.co/fXWy7KM/20.jpg" },
      { url: "https://i.ibb.co/rmJNj4V/21.jpg" },
      { url: "https://i.ibb.co/z2qmpz9/22.jpg" },
      { url: "https://i.ibb.co/W02cP5b/23.jpg" },
      { url: "https://i.ibb.co/rFbVPgq/24.jpg" },
      { url: "https://i.ibb.co/zXg8WgJ/25.jpg" },
      { url: "https://i.ibb.co/sms0h6v/26.jpg" },
    ],
  },
  {
    id: 15,
    type: "ბინა",
    status: "ქირავდება",
    namege: "ქირავდება მთაწმინდა, მაყაშვილის ქუჩა",
    nameen: "Apartment in Mtatsminda, Makashvili strt for Rent",
    descriptionge:
      "ქირავდება 140კვ.მ ბინა მთაწმინდაზე. 4 ოთახი, 3 საძინებელი, 2 სველი წერტილი, 3 აივანი. ბინა აღჭურვილია ყველა საჭირო ინვენტარით. 3 კონდენციონერი. კატეგორიული მოთხოვნა: შინაური ცხოველების გარეშე. ფასი 1800$ ",
    descriptionen:
      "140 square meter apartment for rent in Mtatsminda. 4 rooms, 3 bedrooms, 2 bathrooms, 3 balconies. The apartment is equipped with all necessary equipment. 3 air conditioners. Categorical requirement: no pets. The price is $1800",
    image: "https://i.ibb.co/4TQMWtF/1.png",
    imageLg: "https://i.ibb.co/4TQMWtF/1.png",
    country: "თბილისი",
    addressge: "თბილისი, მთაწმინდა, მაყაშვილის ქუჩა",
    addressen: "თბილისი, მთაწმინდა, მაყაშვილის ქუჩა",
    bedrooms: "4",
    bathrooms: "2",
    surface: "140 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "2",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: true,
    },
    price: "1800",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/4TQMWtF/1.png" },
      { url: "https://i.ibb.co/MVSbfLT/2.png" },
      { url: "https://i.ibb.co/x6KsZQ1/3.png" },
      { url: "https://i.ibb.co/JnRCP4f/4.png" },
      { url: "https://i.ibb.co/0rMmhGD/5.png" },
      { url: "https://i.ibb.co/PxY0CR6/6.png" },
      { url: "https://i.ibb.co/jkNqzQF/7.png" },
      { url: "https://i.ibb.co/VvgNnzF/8.png" },
      { url: "https://i.ibb.co/rvs0j8B/9.png" },
      { url: "https://i.ibb.co/xLHMDnx/10.png" },
      { url: "https://i.ibb.co/S38HfJV/11.png" },
      { url: "https://i.ibb.co/0C5S6jv/12.png" },
      { url: "https://i.ibb.co/YZn6zMD/13.png" },
    ],
  },
  {
    id: 16,
    type: "სახლი",
    status: "იყიდება",
    namege: "იყიდება სახლი საგურამოში",
    nameen: "House in Saguramo for sale",
    descriptionge:
      "იყიდება სახლი საგურამოში, სახლი სრულად აღჭურვილია კომუნიკაციებით, ავეჯი და ტექნიკა, აუზი, კალათბურთის მოედანი. სახლი არის 2 სართულიანი, სულ 9 ოთახი, 6 საძინებელი, 7სველი წერტილი. საცხოვრებელი ფართი შეადგენს 770 კვ.მ ეზო 1600კვ.მ ფასი 1 100 100 $  ",
    descriptionen:
      "House for sale in Saguramo, the house is fully equipped with communications, furniture and appliances, pool, basketball court. The house has 2 floors, a total of 9 rooms, 6 bedrooms, 7 bathrooms. The living area is 770 sq.m, the yard is 1600 sq.m, the price is $1,100,100",
    image: "https://i.ibb.co/kyDt8fC/6.jpg",
    imageLg: "https://i.ibb.co/kyDt8fC/6.jpg",
    country: "საგურამო",
    addressge: "საგურამო",
    addressen: "Saguramo",
    bedrooms: "9",
    bathrooms: "7",
    surface: "770 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "7",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: true,
    },
    price: "1 100 100",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/vdJ9YNq/1.jpg" },
      { url: "https://i.ibb.co/ccfXfs3/2.jpg" },
      { url: "https://i.ibb.co/kSR5G1P/3.jpg" },
      { url: "https://i.ibb.co/QJ3Fmq8/4.jpg" },
      { url: "https://i.ibb.co/T1YT8qw/5.jpg" },
      { url: "https://i.ibb.co/4fSHTGr/6.jpg" },
      { url: "https://i.ibb.co/R0v2n2R/7.jpg" },
      { url: "https://i.ibb.co/4YWbbZ1/8.jpg" },
      { url: "https://i.ibb.co/TPGcM9Y/9.jpg" },
      { url: "https://i.ibb.co/djQ9mS9/10.jpg" },
      { url: "https://i.ibb.co/nsPr4KN/11.jpg" },
      { url: "https://i.ibb.co/yfgW0Kn/12.jpg" },
      { url: "https://i.ibb.co/ThvXSXB/13.jpg" },
      { url: "https://i.ibb.co/4Fg9mgV/14.jpg" },
      { url: "https://i.ibb.co/Sm6sHm2/15.jpg" },
      { url: "https://i.ibb.co/1T9nRkM/16.jpg" },
      { url: "https://i.ibb.co/Vmbm1LG/18.jpg" },
      { url: "https://i.ibb.co/zNz7sMQ/19.jpg" },
      { url: "https://i.ibb.co/0GGgtft/20.jpg" },
      { url: "https://i.ibb.co/5xXNjbJ/21.jpg" },
      { url: "https://i.ibb.co/R0v2n2R/7.jpg" },
    ],
  },
  {
    id: 17,
    type: "სახლი",
    status: "იყიდება",
    namege: "იყიდება სახლი დიდ დიღომში, ჰიუგო ჰიუპერტის ქუჩა",
    nameen: "House in Did Dighomi, Hugo Hubert strt for sale",
    descriptionge:
      "იყიდება სახლი დიდ დღომში 330 კვ, 2 სართული ახალი აშენებული, მიწა არის არა- სასოფლო, 600 კვ, ახალი ევრორემონტით, უცხოვრებელი, 5 საძინებელი, 3 სვ წერტილი, ავეჯით, ბასეინით, სულ 6 ოთახი ჰიუგო ჰიუპერტის ქუჩა ",
    descriptionen:
      "House for sale in Didi Dighomi, 330 sq.m., 2 floors, newly built, land is non-rural, 600 sq.m., newly renovated, uninhabited, 5 bedrooms, 3 bathrooms, furnished, with pool, total 6 rooms Hugo Hupert Street ",
    image: "https://i.ibb.co/QdYYn1H/10.jpg",
    imageLg: "https://i.ibb.co/QdYYn1H/10.jpg",
    country: "თბილისი",
    addressge: "თბილისი, დიდ დიღომი, ჰიუგო ჰიუპერტის ქუჩა",
    addressen: "Tbilisi, Didi Dighomi, Hugo Hubert strt",
    bedrooms: "6",
    bathrooms: "3",
    surface: "330 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "3",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: true,
    },
    price: "460 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/QdYYn1H/10.jpg" },
      { url: "https://i.ibb.co/PzbDvJr/1.jpg" },
      { url: "https://i.ibb.co/WGdXMRc/2.jpg" },
      { url: "https://i.ibb.co/0MTGRFp/3.jpg" },
      { url: "https://i.ibb.co/2hTD08p/4.jpg" },
      { url: "https://i.ibb.co/7gcWjT4/5.jpg" },
      { url: "https://i.ibb.co/pKzvnzX/6.jpg" },
      { url: "https://i.ibb.co/3N1HTbM/7.jpg" },
      { url: "https://i.ibb.co/jwhF0rB/8.jpg" },
      { url: "https://i.ibb.co/mDKZDx2/9.jpg" },
      { url: "https://i.ibb.co/wzt27zW/11.jpg" },
      { url: "https://i.ibb.co/TBJrKL8/12.jpg" },
      { url: "https://i.ibb.co/6sBnscY/13.jpg" },
      { url: "https://i.ibb.co/yVNGC8Y/14.jpg" },
    ],
  },
  {
    id: 18,
    type: "სახლი",
    status: "იყიდება",
    namege: "იყიდება სახლი დიღმისველში, ბარონ დე ბაის ქუჩა",
    nameen: "House in Dighmisveli, Baron De Bais street for sale",
    descriptionge:
      "იყიდება სახლი დიღმისველში ბარონ დე ბაის ქუჩაზე, სახლი 200 კვმ, 6 ოთახი. მიწა 500კვმ. ფასი 370 000$ ",
    descriptionen:
      " House for sale in Digmisveli on Baron de Bai street, house 200 sq m, 6 rooms. The land is 500 square meters. The price is $370,000",
    image: "https://i.ibb.co/cDL7rC5/1.jpg",
    imageLg: "https://i.ibb.co/cDL7rC5/1.jpg",
    country: "თბილისი",
    addressge: "თბილისი, დიღმისველი, ბარონ დე ბაის ქუჩა",
    addressen: "Tbilisi, Dighmisveli, Baron De Bais",
    bedrooms: "6",
    bathrooms: "2",
    surface: "200 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "2",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: true,
    },
    price: "340 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/cDL7rC5/1.jpg" },
      { url: "https://i.ibb.co/4MFGRt3/2.jpg" },
      { url: "https://i.ibb.co/T2Fpgjc/3.jpg" },
      { url: "https://i.ibb.co/KNJrSzP/4.jpg" },
      { url: "https://i.ibb.co/XCVL8Gc/5.jpg" },
      { url: "https://i.ibb.co/f47mtmh/6.jpg" },
      { url: "https://i.ibb.co/kh1c566/7.jpg" },
      { url: "https://i.ibb.co/3yDsxjw/8.jpg" },
      { url: "https://i.ibb.co/FgH9xL7/9.jpg" },
      { url: "https://i.ibb.co/rM2Kzkk/10.jpg" },
      { url: "https://i.ibb.co/zh8mL8D/11.jpg" },
      { url: "https://i.ibb.co/Jx2KrVr/12.jpg" },
      { url: "https://i.ibb.co/4mxRLkq/13.jpg" },
      { url: "https://i.ibb.co/DpHgbvd/14.jpg" },
      { url: "https://i.ibb.co/2yZVbWs/15.jpg" },
      { url: "https://i.ibb.co/LYRtwsd/16.jpg" },
      { url: "https://i.ibb.co/JHVGyJk/17.jpg" },
      { url: "https://i.ibb.co/Dr77Xgc/18.jpg" },
      { url: "https://i.ibb.co/VNmn5SQ/19" },
      { url: "https://i.ibb.co/bHy8kdH/20.jpg" },
      { url: "https://i.ibb.co/BVs4mRS/21.jpg" },
      { url: "https://i.ibb.co/dpY3WxP/22.jpg" },
    ],
  },
  {
    id: 19,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება ბინა ვაკეში, თამარაშვილის ქუჩა",
    nameen: "Apartment in Vake, Tamarashvili street for sale",
    descriptionge:
      "იყიდება ბინა ვაკეში თამარაშვილის ქუჩაზე, 15 წლის აშენებულ სახლში დუპლექსის ტიპის ბინა, 290კვმ. 5 ოთახი, 4 საძინებლითმ 4 სველი წერტილით, ევრო რემონტი ნაწილობრივ ავეჯით., ფასი 630 000$  ",
    descriptionen:
      "Apartment for sale in Vake on Tamarashvili Street, duplex apartment in a 15-year-old house, 290 sq.m. 5 rooms, 4 bedrooms with 4 bathrooms, Euro renovation partially furnished, price $630,000",
    image: "https://i.ibb.co/R7LtF72/4.jpg",
    imageLg: "https://i.ibb.co/R7LtF72/4.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ვაკე, თამარაშვილის ქუჩა",
    addressen: "Tbilisi, Vake, Tamarashvili street",
    bedrooms: "5",
    bathrooms: "4",
    surface: "290 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "4",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: true,
    },
    price: "630 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/XFyGKWC/1.jpg" },
      { url: "https://i.ibb.co/j6XgGQk/2.jpg" },
      { url: "https://i.ibb.co/fGHj1Cg/3.jpg" },
      { url: "https://i.ibb.co/R7LtF72/4.jpg" },
      { url: "https://i.ibb.co/nPYW1R0/5.jpg" },
      { url: "https://i.ibb.co/KrBJxw3/6.jpg" },
      { url: "https://i.ibb.co/6NCNyjb/7.jpg" },
      { url: "https://i.ibb.co/1QKF4hy/8.jpg" },
      { url: "https://i.ibb.co/pW3tBdW/9.jpg" },
      { url: "https://i.ibb.co/KsMMVG2/11.jpg" },
      { url: "https://i.ibb.co/qrBqw4s/12.jpg" },
      { url: "https://i.ibb.co/LvcGLHq/13.jpg" },
      { url: "https://i.ibb.co/0mVC39s/14.jpg" },
      { url: "https://i.ibb.co/64PzfRG/15.jpg" },
    ],
  },
  {
    id: 20,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება ბინა ვაკეში, აბაშიძის ქუჩა",
    nameen: "Apartment in Vake, Abashidze strt for sale",
    descriptionge:
      "იყიდება ბინა ვაკეში აბაშიძის ქუჩაზე, ვაკის პარკთან 50 მეტრში, ხედი პარკზე ასევე ჭავჭავაძეზე, 3 საძინებელი, სამზარეულო, მისაღები, 3 სველი წერტილი ბინა არის დუპლექსი ფასი 450 000$  ",
    descriptionen:
      "Apartment for sale in Vake, on Abashidze Street, 50 meters from Vaki Park, view of the park and Chavchavadze, 3 bedrooms, kitchen, living room, 3 bathrooms. The apartment is a duplex. The price is $450,000.",
    image: "https://i.ibb.co/VNfmGZv/1.jpg",
    imageLg: "https://i.ibb.co/VNfmGZv/1.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ვაკე, ი.აბაშიძის ქუჩა",
    addressen: "Tbilisi, Vake, Abashidze strt",
    bedrooms: "5",
    bathrooms: "3",
    surface: "150 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "3",
      heat: "ცენტრალური",
      parking: false,
      furniture: true,
      elevator: true,
    },
    price: "420 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/X4c8Pgz/1.jpg" },
      { url: "https://i.ibb.co/GcZ2jvb/2.jpg" },
      { url: "https://i.ibb.co/rZX2ZQ9/3.jpg" },
      { url: "https://i.ibb.co/JBbdXNW/4.jpg" },
      { url: "https://i.ibb.co/CMG7wrK/5.jpg" },
      { url: "https://i.ibb.co/0h4VKNT/6.jpg" },
      { url: "https://i.ibb.co/fQ9BR2H/7.jpg" },
      { url: "https://i.ibb.co/Lzg43t5/8.jpg" },
      { url: "https://i.ibb.co/wJK7vJF/9.jpg" },
      { url: "https://i.ibb.co/v4wXdgB/10.jpg" },
      { url: "https://i.ibb.co/CK8db7C/11.jpg" },
      { url: "https://i.ibb.co/F5sQQc1/12.jpg" },
      { url: "https://i.ibb.co/PNkSXpy/13.jpg" },
      { url: "https://i.ibb.co/hCNX2V3/14.jpg" },
      { url: "https://i.ibb.co/DVbkNMS/15.jpg" },
      { url: "https://i.ibb.co/M2K7J5H/16.jpg" },
      { url: "https://i.ibb.co/JpPPSJK/17.jpg" },
      { url: "https://i.ibb.co/XY5x9ks/18.jpg" },
      { url: "https://i.ibb.co/Qjr3KtG/19.jpg" },
      { url: "https://i.ibb.co/yPZNFKq/20.jpg" },
    ],
  },
  {
    id: 21,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება ბინა ვაკეში, თამარაშვილის ქუჩა",
    nameen: "Apartment in Vake, Tamarashvili strt for sale",
    descriptionge:
      "იყიდება პენტჰაუსი მაღალი ხარისხის რემონტით, ნაწილობრივ ავეჯით საცხოვრებელი 600+170 კვ. 2 ვერანდა.  ",
    descriptionen:
      "Penthouse for sale with high-quality renovation, partially furnished living space 600+170 sq.m. 2 porches.",
    image: "https://i.ibb.co/9yjwz00/57.jpg",
    imageLg: "https://i.ibb.co/9yjwz00/57.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ვაკე, თამარაშვილის ქუჩა",
    addressen: "Tbilisi vake, Tamarashvili strt",
    bedrooms: "8",
    bathrooms: "5",
    surface: "770 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "5",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: true,
    },
    price: "1 800 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/FWVZbYp/1.jpg" },
      { url: "https://i.ibb.co/6msJ0gb/3.jpg" },
      { url: "https://i.ibb.co/ysNpMQ1/5.jpg" },
      { url: "https://i.ibb.co/nCMPRW7/7.jpg" },
      { url: "https://i.ibb.co/q9wx8Y5/9.jpg" },
      { url: "https://i.ibb.co/K2nryNB/14.jpg" },
      { url: "https://i.ibb.co/2nK4nVF/21.jpg" },
      { url: "https://i.ibb.co/LNxsPB3/27.jpg" },
      { url: "https://i.ibb.co/nCdgB26/32.jpg" },
      { url: "https://i.ibb.co/17wF4pg/35.jpg" },
      { url: "https://i.ibb.co/hRjNz3m/42.jpg" },
      { url: "https://i.ibb.co/RzpbcT1/47.jpg" },
      { url: "https://i.ibb.co/bNnHqVV/57.jpg" },
    ],
  },
  {
    id: 22,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება ბინა ვაკეში, აბაშიძის ქუჩა",
    nameen: "Apartment in Vake, Abashidze strt for sale",
    descriptionge:
      "იყიდება 2 ოთახიანი ბინა აბაშიძეზე 70 კვმ. 1 სველი წერტილი ევრორემონტით, ავეჯით, 24/7 დაცვით. სართული 14/14 ლამაზი ხედით  ",
    descriptionen:
      "2-room apartment for sale in Abashidze, 70 sq.m. 1 bathroom with Euro renovation, furniture, 24/7 security. Floor 14/14 with a beautiful view",
    image: "https://i.ibb.co/f0Q2Zfx/7.jpg",
    imageLg: "https://i.ibb.co/f0Q2Zfx/7.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ვაკე, აბაშიძის ქუჩა",
    addressge: "Tbilisi, Vake, Abashidze strt",
    bedrooms: "2",
    bathrooms: "1",
    surface: "70 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "1",
      heat: "ცენტრალური",
      parking: false,
      furniture: true,
      elevator: true,
    },
    price: "250 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/d66QmBw/1.jpg" },
      { url: "https://i.ibb.co/R4ZfB1v/2.jpg" },
      { url: "https://i.ibb.co/YBRPQnT/3.jpg" },
      { url: "https://i.ibb.co/B2sVPNr/4.jpg" },
      { url: "https://i.ibb.co/tcv2tQV/5.jpg" },
      { url: "https://i.ibb.co/mNK0Dpc/6.jpg" },
      { url: "https://i.ibb.co/DKLtmhR/7.jpg" },
      { url: "https://i.ibb.co/41SJqPW/8.jpg" },
      { url: "https://i.ibb.co/0ZLBHCs/9.jpg" },
      { url: "https://i.ibb.co/99V567k/10.jpg" },
      { url: "https://i.ibb.co/jk868Pt/11.jpg" },
      { url: "https://i.ibb.co/Rz1MVGx/12.jpg" },
    ],
  },
  {
    id: 23,
    type: "ბინა",
    status: "ქირავდება",
    namege: "იყიდება ბინა ვაკეში, ვაკის პარკთან",
    nameen: "Apartment in Vake, Vakis Park for rent",
    descriptionge:
      "ქირავდება 2 ოთახიანი  ბინა პრემიუმ სახლში ვაკის პარკთან 2000$, 68 კვ ",
    descriptionge:
      "2-room apartment for rent in a premium house near Vaki Park, $2,000, 68 sq.m",
    image: "https://i.ibb.co/8jMSsmw/1.jpg",
    imageLg: "https://i.ibb.co/8jMSsmw/1.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ვაკე, ვაკის პარკთან",
    addressen: "Tbilisi, Vake, Vakis park",
    bedrooms: "2",
    bathrooms: "1",
    surface: "68 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "1",
      heat: "ცენტრალური",
      parking: false,
      furniture: true,
      elevator: true,
    },
    price: "2000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/P5xfjry/1.jpg" },
      { url: "https://i.ibb.co/fNksxky/2.jpg" },
      { url: "https://i.ibb.co/D5ZspLh/3.jpg" },
      { url: "https://i.ibb.co/0X14d5v/4.jpg" },
      { url: "https://i.ibb.co/T811gPW/6.jpg" },
      { url: "https://i.ibb.co/84HhxnH/7.jpg" },
      { url: "https://i.ibb.co/pz8yq2r/9.jpg" },
      { url: "https://i.ibb.co/5M5PhLS/10.jpg" },
      { url: "https://i.ibb.co/tsJ21Q6/11.jpg" },
      { url: "https://i.ibb.co/GsdG4Kw/12.jpg" },
      { url: "https://i.ibb.co/PTfQHNr/13.jpg" },
    ],
  },
  {
    id: 24,
    type: "სახლი",
    status: "იყიდება",
    namege: "იყიდება სახლი წავკისში",
    nameen: "House in Tsavkisi for sale",
    descriptionge:
      "იყიდება ახალ აშენებული სახლი წავკისში 220 კვმ. მიწა: 500კვმ. 5 ოთახი, 4 საძინებელი 3 სველი წერტილი. პრესტიჟულ უბანში, რემონტი დასრულდა 2023 წლი მაისში, სახლი აღჭურვილია უმაღლესი ხარისხის ავეჯით და ტექნიკით. გაყვანილია ცენტრალური და ასევე იატაკქვეშ გათბობა. უმაღლესი ხარისხის მეტალის კარ-ფანჯარა. თანამედროვე აუზია, ფილრაციით, წყლის გათბობნიბს სისტემით, განათებით. ეზოში გაყვანილია სარწყავი სისტემა. ასევე დამონტაჟაბულია კამერები მთელ პერიმეტრზე. ",
    descriptionen:
      "Newly built house for sale in Tsavkis, 220 sq.m. Land: 500 sq.m. 5 rooms, 4 bedrooms, 3 bathrooms. In a prestigious area, the renovation was completed in May 2023, the house is equipped with the highest quality furniture and appliances. Central and underfloor heating are installed. High quality metal door-window. There is a modern pool with filtration, water heating system, lighting. There is an irrigation system in the yard. Cameras are also installed on the entire perimeter.",
    image: "https://i.ibb.co/9qbRPZZ/18.jpg",
    imageLg: "https://i.ibb.co/9qbRPZZ/18.jpg",
    country: "წავკისი",
    addressge: "წავკისი",
    addressen: "Tsavkisi",
    bedrooms: "5",
    bathrooms: "3",
    surface: "220 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "3",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: true,
    },
    price: "400 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/vDKwKSq/1.jpg" },
      { url: "https://i.ibb.co/2yVMvq1/2.jpg" },
      { url: "https://i.ibb.co/6yx8Bnk/3.jpg" },
      { url: "https://i.ibb.co/mvmQXhN/4.jpg" },
      { url: "https://i.ibb.co/Yk09NQ3/5.jpg" },
      { url: "https://i.ibb.co/dM5qCZM/6.jpg" },
      { url: "https://i.ibb.co/b6KHR28/7.jpg" },
      { url: "https://i.ibb.co/KsXYy4c/8.jpg" },
      { url: "https://i.ibb.co/PGfMWbp/9.jpg" },
      { url: "https://i.ibb.co/87Pm8dF/10.jpg" },
      { url: "https://i.ibb.co/brzQFTg/11.jpg" },
      { url: "https://i.ibb.co/k4f5m2J/12.jpg" },
      { url: "https://i.ibb.co/ZgTLjgc/13.jpg" },
      { url: "https://i.ibb.co/P1yQdHY/14.jpg" },
      { url: "https://i.ibb.co/vqf8Gzz/15.jpg" },
      { url: "https://i.ibb.co/2s5rHSH/16.jpg" },
      { url: "https://i.ibb.co/16Nw139/17.jpg" },
      { url: "https://i.ibb.co/9qbRPZZ/18.jpg" },
      { url: "https://i.ibb.co/ysZcB2d/19.jpg" },
      { url: "https://i.ibb.co/4srL70c/20.jpg" },
      { url: "https://i.ibb.co/WscMVJV/21.jpg" },
      { url: "https://i.ibb.co/YhG34qJ/22.jpg" },
      { url: "https://i.ibb.co/6tSpDfM/24.jpg" },
    ],
  },
  {
    id: 25,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება ბინა ი.ჭავჭავაძეზე",
    nameen: "Apartment at Ilia Chavchavadze strt for sale",
    descriptionge:
      "4 ოთახიანი ბინა ჭავჭავაძეზე  პრემიუმ კლასის სახლში 5 სართული 24 საათიანი დაცვით 137 კვ 2 სვ წერტ ავეჯით ტექნიკით 500000$",
    descriptionen:
      "4-room apartment in a premium class house on Chavchavadze, 5 floors with 24-hour security, 137 square meters, 2 square meters, furniture, appliances, $500,000",
    image: "https://i.ibb.co/sqcPmnr/4.jpg",
    imageLg: "https://i.ibb.co/sqcPmnr/4.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ი.ჭავჭავაძე",
    addressen: "Tbilisi. I.Chavchavadze",
    bedrooms: "4",
    bathrooms: "2",
    surface: "137 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "2",
      heat: "ცენტრალური",
      parking: false,
      furniture: true,
      elevator: true,
    },
    price: "500 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/XSYTBKr/1.jpg" },
      { url: "https://i.ibb.co/47s8J34/2.jpg" },
      { url: "https://i.ibb.co/sqcPmnr/4.jpg" },
      { url: "https://i.ibb.co/0JDQZ2V/5.jpg" },
      { url: "https://i.ibb.co/bNVVwP8/6.jpg" },
      { url: "https://i.ibb.co/1by8h6t/7.jpg" },
      { url: "https://i.ibb.co/7CW4DW4/8.jpg" },
      { url: "https://i.ibb.co/F6ZhCDq/10.jpg" },
      { url: "https://i.ibb.co/bL2VVqG/11.jpg" },
      { url: "https://i.ibb.co/PQLZbYJ/12.jpg" },
      { url: "https://i.ibb.co/f0y2yL2/13.jpg" },
      { url: "https://i.ibb.co/3BTcV7k/14.jpg" },
    ],
  },
  {
    id: 26,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება ბინა ნ.ჟვანიაზე",
    nameen: "Apartment in Vake, Nino Zhvania strt for sale",
    descriptionge:
      "იყიდება 3 ოთახიანი ბინა ნინო ჟვანიაზე, 2 საძინებელი, ახალი გარემონტებული მაღალი ხარისხის მასალებით უცხოვრებელი, შესატანია მხოლოდ ავეჯი",
    descriptionen:
      "3-room apartment for sale on Nino Zhvania, 2 bedrooms, newly renovated with high-quality materials, uninhabitable, only furniture is included",
    image: "https://i.ibb.co/N6NzDkk/8.jpg",
    imageLg: "https://i.ibb.co/N6NzDkk/8.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ნ.ჟვანია",
    addressen: "Tbilisi, N.Zhvania",
    bedrooms: "3",
    bathrooms: "1",
    surface: "130 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "2",
      heat: "ცენტრალური",
      parking: false,
      furniture: false,
      elevator: true,
    },
    price: "220 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/PckRFx7/1.jpg" },
      { url: "https://i.ibb.co/b7k7cZQ/2.jpg" },
      { url: "https://i.ibb.co/tbrQVNT/3.jpg" },
      { url: "https://i.ibb.co/M8dbWNd/4.jpg" },
      { url: "https://i.ibb.co/XYKDf7s/5.jpg" },
      { url: "https://i.ibb.co/r5VfVy6/6.jpg" },
      { url: "https://i.ibb.co/WWr2Pnx/7.jpg" },
      { url: "https://i.ibb.co/4ZSxvDD/8.jpg" },
      { url: "https://i.ibb.co/QKSLX87/9.jpg" },
      { url: "https://i.ibb.co/VStTnhD/10.jpg" },
      { url: "https://i.ibb.co/QCWbBc9/11.jpg" },
      { url: "https://i.ibb.co/410cNZr/12.jpg" },
      { url: "https://i.ibb.co/hsNFv57/13.jpg" },
      { url: "https://i.ibb.co/gWjbB08/15.jpg" },
      { url: "https://i.ibb.co/2F0j5Vj/16.jpg" },
    ],
  },
  {
    id: 27,
    type: "სახლი",
    status: "იყიდება",
    namege: "იყიდება კერძო სახლი ლისის აგარაკებზე",
    nameen: "House in Lisi Lake territory for sale",
    descriptionge:
      "იყიდება კერძო სახლი ლისის მიდებარე ტერიტორიაზე. ეკოლოგიურად სუფთა გარემოში, ნუშის ხეების ტყის პირას. სახლს აქვს პანორამული ხედები როგორც ლისის ტბაზე ასევე მთელ თბილისზე. სახლის ფართობია 600კვმ. მიწის ფართობი 820 კვმ. 6 საძინებელი, კაბინეტი, იზოლირებული სამზარეულო 27კვმ. ჭერის სიმაღლე 4მ. მისაღები ოთახი 80კვმ, მარანი 45კვმ, მანსარდი 60კვმ, 7 სველი წერტილი, საერთო ტერასა 120კვმ, 4 იზოლირებული ტერასა თითო 11კვმ, 2 აივანი, გარე სათავსო 20კვმ, პარკინგი ეზოში. კერძო პარკინგი ეზოს მიმდებარედ 8 მანქანაზე. ღია საცურაო აუზი 10/4. ",
    descriptionen:
      "Private house for sale in the area near Lisi. In an ecologically clean environment, on the edge of a forest of almond trees. The house has panoramic views of Lisi Lake and the whole of Tbilisi. The area of ​​the house is 600 square meters. The land area is 820 sq.m. 6 bedrooms, office, isolated kitchen 27 sq.m. The ceiling height is 4m. Living room 80 sq.m., cellar 45 sq.m., attic 60 sq.m., 7 bathrooms, common terrace 120 sq.m., 4 isolated terraces each 11 sq.m., 2 balconies, outdoor storage room 20 sq.m., parking in the yard. Private parking for 8 cars near the yard. Open Swimming Pool 10/4",
    image: "https://i.ibb.co/8DZ1z8r/1.jpg",
    imageLg: "https://i.ibb.co/8DZ1z8r/1.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ლისი",
    addressen: "Tbilisi, Lisi",
    bedrooms: "6",
    bathrooms: "7",
    surface: "600 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "7",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: false,
    },
    price: "980 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/8DZ1z8r/1.jpg" },
      { url: "https://i.ibb.co/BZDp98X/2.jpg" },
      { url: "https://i.ibb.co/gtGZ0Xn/3.jpg" },
      { url: "https://i.ibb.co/yRH3kLg/4.jpg" },
      { url: "https://i.ibb.co/bXsxq5q/5.jpg" },
      { url: "https://i.ibb.co/C9BTX33/6.jpg" },
      { url: "https://i.ibb.co/TP47nsn/7.jpg" },
      { url: "https://i.ibb.co/0QmRtY9/8.jpg" },
      { url: "https://i.ibb.co/nfhmjWK/9.jpg" },
      { url: "https://i.ibb.co/L1W0STn/10.jpg" },
      { url: "https://i.ibb.co/6v4q8Nc/11.jpg" },
      { url: "https://i.ibb.co/WBx6MBN/12.jpg" },
      { url: "https://i.ibb.co/8m11MGy/13.jpg" },
      { url: "https://i.ibb.co/W0kLyhn/14.jpg" },
      { url: "https://i.ibb.co/y5NSZTS/15.jpg" },
      { url: "https://i.ibb.co/PcNzM9m/16.jpg" },
      { url: "https://i.ibb.co/B3fNZZv/17.jpg" },
      { url: "https://i.ibb.co/gv3Fckt/18.jpg" },
      { url: "https://i.ibb.co/1XRTvZz/19.jpg" },
      { url: "https://i.ibb.co/m0003Mt/20.jpg" },
      { url: "https://i.ibb.co/18xdLmw/21.jpg" },
      { url: "https://i.ibb.co/vBFwqGb/22.jpg" },
    ],
  },
  {
    id: 28,
    type: "სახლი",
    status: "იყიდება",
    namege: "იყიდება  სახლი დიღმის 8ში",
    nameen: "House in Dighomi for sale",
    descriptionge:
      "იყიდება ახალი გარემონტებული სახლი თბილისი მოლიდან 600 მეტრში. 1 სართულზე განთავსებულია 1 სველი წერტილი, გარდერობი ტექნიკური ოთახი, კაბინეტი, სამზარეულო და დიდი მისაღები. მეორე სართულზე 2 სველი წერტილი და ოთხი საძინებელი, აქედან ერთი მასტერბედრუმი. მესამე სართულზე 40 კვარდრატული ოთახი, რომელიც თქვენი შეხედულებით შეგიძლიათ გამოიყენოთ. როგორც საძინებლად ასევე სავარჯიშო ან მოსასვენებელ სივრცედ. სახლიდან და ეზოდან იშლება მთის ხედები. მიწა 641კვმ",
    descriptionen:
      "Newly renovated house for sale, 600 meters from Tbilisi Mall. 1 ceremonial house has 1 bathroom, wardrobe, utility room, office, kitchen and large kitchen. On the second floor there are 2 bathrooms and four bedrooms, one master bedroom. On the third floor there are 40 square meter rooms that you can use. Both as a bedroom and as an exercise or rest space. There are mountain views from the house and the yard.",
    image: "https://i.ibb.co/5Wz1hCD/1.jpg",
    imageLg: "https://i.ibb.co/5Wz1hCD/1.jpg",
    country: "თბილისი",
    addressge: "თბილისი, დიღომი",
    addressen: "Tbilisi, Dighomi",
    bedrooms: "7",
    bathrooms: "3",
    surface: "433 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "3",
      heat: "ცენტრალური",
      parking: true,
      furniture: false,
      elevator: false,
    },
    price: "770 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/5Wz1hCD/1.jpg" },
      { url: "https://i.ibb.co/gV6r1GM/2.jpg" },
      { url: "https://i.ibb.co/yktYFYw/3.jpg" },
      { url: "https://i.ibb.co/jrZ5wBY/4.jpg" },
      { url: "https://i.ibb.co/chT7Lkj/5.jpg" },
      { url: "https://i.ibb.co/bQR3n5z/6.jpg" },
      { url: "https://i.ibb.co/8dykmnn/7.jpg" },
      { url: "https://i.ibb.co/wL2VsF6/8.jpg" },
      { url: "https://i.ibb.co/GkmGBtT/9.jpg" },
      { url: "https://i.ibb.co/27yK3jY/10.jpg" },
      { url: "https://i.ibb.co/FKP2ghX/11.jpg" },
      { url: "https://i.ibb.co/NLVZ4TP/12.jpg" },
      { url: "https://i.ibb.co/FgMSzwx/13.jpg" },
      { url: "https://i.ibb.co/fpVtN3n/14.jpg" },
      { url: "https://i.ibb.co/4MsLc90/15.jpg" },
      { url: "https://i.ibb.co/r4wTzNK/16.jpg" },
      { url: "https://i.ibb.co/k1KnQ0q/17.jpg" },
      { url: "https://i.ibb.co/XxzWwht/18.jpg" },
      { url: "https://i.ibb.co/fYz5WBD/19.jpg" },
      { url: "https://i.ibb.co/WfTbqCV/20.jpg" },
    ],
  },
  {
    id: 29,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება  ბინა ქობულეთის ქუჩაზე",
    nameen: "Apartment for sale on Kobuleti Street",
    descriptionge:
      "იყიდება 207 კვმ ბინა, ქობულეთის ქუჩაზე, 9 სართულიანი კორპუსის 9 სართულზე. აქვს 3 საძინებელი, 3 სველი წერტილი + დიდი ზომის სამრეცხაო, 2 შესასვლელი გარდერობი, 2 აივანი და ერთი დიდი ვერანდა, სამზარეულო, მისაღები ოთახი. ბინა არის გარემონტებული, ავეჯით და ტექნიკით. მოყვება 2 ადგილიანი გარაჟი.",
    descriptionen:
      "207 sq m apartment for sale, on Kobuleti Street, on the 9th floor of a 9-storey building. It has 3 bedrooms, 3 bathrooms + large laundry room, 2 entrance wardrobes, 2 balconies and one large veranda, kitchen, living room. The apartment is renovated, furnished and equipped. 2-car garage included.",
    image: "https://i.ibb.co/znrBBL1/IMG-20231203-WA0011.jpg",
    imageLg: "https://i.ibb.co/znrBBL1/IMG-20231203-WA0011.jpg",
    country: "თბილისი",
    addressge: "თბილისი, ვაკე",
    addressen: "Tbilisi, Vake",
    bedrooms: "3",
    bathrooms: "3",
    surface: "207 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "3",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: false,
    },
    price: "390 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/hFVyRfb/IMG-20231203-WA0007.jpg" },
      { url: "https://i.ibb.co/zsjyMSX/IMG-20231203-WA0008.jpg" },
      { url: "https://i.ibb.co/NssYDtk/IMG-20231203-WA0009.jpg" },
      { url: "https://i.ibb.co/n8bVbCp/IMG-20231203-WA0010.jpg" },
      { url: "https://i.ibb.co/znrBBL1/IMG-20231203-WA0011.jpg" },
      { url: "https://i.ibb.co/KsSLpqs/IMG-20231203-WA0012.jpg" },
      { url: "https://i.ibb.co/fQ7x10M/IMG-20231203-WA0013.jpg" },
      { url: "https://i.ibb.co/rxjhqVN/IMG-20231203-WA0014.jpg" },
      { url: "https://i.ibb.co/dpsh4Wz/IMG-20231203-WA0015.jpg" },
      { url: "https://i.ibb.co/1dvqQQY/IMG-20231203-WA0016.jpg" },
      { url: "https://i.ibb.co/HpkhNcp/IMG-20231203-WA0017.jpg" },
      { url: "https://i.ibb.co/wsGdv7b/IMG-20231203-WA0018.jpg" },
      { url: "https://i.ibb.co/2KMWrJ7/IMG-20231203-WA0019.jpg" },
      { url: "https://i.ibb.co/r4T4NSC/IMG-20231203-WA0020.jpg" },
      { url: "https://i.ibb.co/80wBQY0/IMG-20231203-WA0021.jpg" },
      { url: "https://i.ibb.co/jLrn8hZ/IMG-20231203-WA0022.jpg" },
      { url: "https://i.ibb.co/ZMzGyxS/IMG-20231203-WA0023.jpg" },
      { url: "https://i.ibb.co/hmMRbFw/IMG-20231203-WA0024.jpg" },
      { url: "https://i.ibb.co/LJDDG3X/IMG-20231203-WA0026.jpg" },
    ],
  },
  {
    id: 30,
    type: "სახლი",
    status: "იყიდება",
    namege: "იყიდება სახლი წყნეთში",
    nameen: "House for sale in Tskneti",
    descriptionge:
      "წყნეთში, იყიდება ან ქირავდება კერძო 3 სართულიანი სახლი. 951კვ.მ საკუთარი ეზოთი და საცურაო აუზით. სახლს გააჩნია 8 ავტომობილზე გათვლილი პარკინგის სივრცე. სახლში არის 5 საძინებელი ოთახი. 3 არის დუპლექსური ტიპის დამოუკიდებელი მისაღები ოთახით, საძინებლით აივნით და სველი წერტილებით. არის 2 სამზარეულო, ბიბლიოთეკა, კაბინეტი, გარდერობი. მისაღები სივრცეები, 9 სველი წერტილი, 2 ბუხარი 1 ტერასა და 5 აივანი.",
    descriptionen:
      "In Tskneti, a private 3-storey house is for sale or rent. 951 sq.m. with its own yard and swimming pool. The house has a parking space for 8 cars. There are 5 bedrooms in the house. 3 is duplex type with independent room, bedroom with balcony and bathrooms. There are 2 kitchens, a library, a cabinet, a wardrobe. Open spaces, 9 bathrooms, 2 fireplaces, 1 terrace and 5 balconies.",
    image: "https://i.ibb.co/3TC47P7/IMG-20240227-WA0002.jpg",
    imageLg: "https://i.ibb.co/3TC47P7/IMG-20240227-WA0002.jpg",
    country: "თბილისი",
    addressge: "Tskneti",
    addressen: "Tskneti",
    bedrooms: "5",
    bathrooms: "9",
    surface: "951 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "9",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: false,
    },
    price: "1 750 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/3TC47P7/IMG-20240227-WA0002.jpg" },
      { url: "https://i.ibb.co/xCh9SsK/IMG-20240227-WA0003.jpg" },
      { url: "https://i.ibb.co/5Fqf72t/IMG-20240227-WA0004.jpg" },
      { url: "https://i.ibb.co/9TRfwS5/IMG-20240227-WA0005.jpg" },
      { url: "https://i.ibb.co/52CP2gm/IMG-20240227-WA0006.jpg" },
      { url: "https://i.ibb.co/mCG4P14/IMG-20240227-WA0007.jpg" },
      { url: "https://i.ibb.co/w72G3nz/IMG-20240227-WA0008.jpg" },
      { url: "https://i.ibb.co/zXtpW2b/IMG-20240227-WA0009.jpg" },
      { url: "https://i.ibb.co/HhNjQkv/IMG-20240227-WA0010.jpg" },
      { url: "https://i.ibb.co/R06J7d0/IMG-20240227-WA0011.jpg" },
      { url: "https://i.ibb.co/V2GcJxG/IMG-20240227-WA0012.jpg" },
      { url: "https://i.ibb.co/xfG1vYq/IMG-20240227-WA0013.jpg" },
      { url: "https://i.ibb.co/3Rhhs2k/IMG-20240227-WA0014.jpg" },
      { url: "https://i.ibb.co/xGC98Fp/IMG-20240227-WA0015.jpg" },
      { url: "https://i.ibb.co/gSzhtKS/IMG-20240227-WA0016.jpg" },
      { url: "https://i.ibb.co/Jk6MNdk/IMG-20240227-WA0017.jpg" },
      { url: "https://i.ibb.co/tXZ5YPF/IMG-20240227-WA0018.jpg" },
      { url: "https://i.ibb.co/mSWbq9g/IMG-20240227-WA0019.jpg" },
      { url: "https://i.ibb.co/qMhJHqS/IMG-20240227-WA0020.jpg" },
      { url: "https://i.ibb.co/CPXL0kn/IMG-20240227-WA0021.jpg" },
      { url: "https://i.ibb.co/mNydC1g/IMG-20240227-WA0022.jpg" },
    ],
  },
  {
    id: 31,
    type: "სახლი",
    status: "იყიდება",
    namege: "იყიდება სახლი კრწანისში",
    nameen: "House for sale in Krtsanisi",
    descriptionge:
      "იყიდება საოცარი სახლი კრწანისის ქუჩაზე, 2019 წელს აშენებული,4 სართული, 1370 კვმ. მიწა  სახლს აქვს ძალიან ლამაზი ეზო, ფანტანით და მწენარეული დეკორაციებით, ეზოში მოწყობილია საზაფხულო სამზარეულო , იდიალურია სტუმრების მისაღებათ. ეზოში არის პარკინგის სივრცე",
    descriptionen:
      "A lovely house for sale on Krtsanisi Street, built in 2019, the house has a very beautiful yard with a fountain and green decorations, there is a summer kitchen in the yard, ideal for entertaining guests. There is a parking space in the yard",
    image: "https://i.ibb.co/4g29FRp/bb.jpg",
    imageLg: "https://i.ibb.co/4g29FRp/bb.jpg",
    country: "თბილისი",
    addressge: "კრწანისი",
    addressen: "Krtsanisi",
    bedrooms: "18",
    bathrooms: "11",
    surface: "1330 კვ.მ",
    details: {
      repair: "ახალი გარემონტებული",
      balcony: true,
      toilet: "11",
      heat: "ცენტრალური",
      parking: true,
      furniture: false,
      elevator: false,
    },
    price: "4 000 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/YZTtZLg/IMG-20240227-WA0024.jpg" },
      { url: "https://i.ibb.co/88Dr5JW/IMG-20240227-WA0026.jpg" },
      { url: "https://i.ibb.co/4g29FRp/bb.jpg" },
      { url: "https://i.ibb.co/Lhc1VcC/IMG-20240227-WA0025.jpg" },
      { url: "https://i.ibb.co/KLJKNg2/IMG-20240227-WA0023.jpg" },
      { url: "https://i.ibb.co/cr9Hv2d/IMG-20240227-WA0027.jpg" },
      { url: "https://i.ibb.co/8cXdYG1/IMG-20240227-WA0028.jpg" },
      { url: "https://i.ibb.co/8xkhJdB/IMG-20240227-WA0029.jpg" },
      { url: "https://i.ibb.co/2ZFXsPt/IMG-20240227-WA0030.jpg" },
      { url: "https://i.ibb.co/w0hPn9V/IMG-20240227-WA0031.jpg" },
    ],
  },
  {
    id: 32,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება ბინა ყაზბეგის ქუჩაზე",
    nameen: "House for sale in Krtsanisi",
    descriptionge:
      "იყიდება ყაზბეგზე ახალ სახლში 12/15 სართულზე,,შესანიშნავი ხედით, 4 ოთახიანი ბინა სტუდიო, დიდი ვიტრაჟული სტუდიო მისაღები ოთახით შესანიშნავი ხედებით, სამზარეულოთი. 8 წლის რემონტით, 2 სვ წერტილი,ნაწილობრივი ავეჯით, აივანი",
    descriptionen:
      "For sale on kazbegi street, in a new house on the 12th/15th floor, with an excellent view, 4-room studio apartment, 8 years of renovation, 2 bedrooms, furnished, balcony",
    image: "https://i.ibb.co/B28jzq2/IMG-20240603-WA0013.jpg",
    imageLg: "https://i.ibb.co/B28jzq2/IMG-20240603-WA0013.jpg",
    country: "თბილისი",
    addressge: "ყაზბეგის გამზირი",
    addressen: "Kazbegi street",
    bedrooms: "4",
    bathrooms: "2",
    surface: "136.50 კვ.მ",
    details: {
      repair: "გარემონტებული",
      balcony: true,
      toilet: "2",
      heat: "ცენტრალური",
      parking: false,
      furniture: true,
      elevator: true,
    },
    price: "240 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/VJ1FtYw/IMG-20240603-WA0002.jpg" },
      { url: "https://i.ibb.co/Dftq6HF/IMG-20240603-WA0003.jpg" },
      { url: "https://i.ibb.co/prjPLXX/IMG-20240603-WA0004.jpg" },
      { url: "https://i.ibb.co/WGWYXm0/IMG-20240603-WA0005.jpg" },
      { url: "https://i.ibb.co/98zw1jn/IMG-20240603-WA0006.jpg" },
      { url: "https://i.ibb.co/WFMYBF1/IMG-20240603-WA0007.jpg" },
      { url: "https://i.ibb.co/1nW19Qc/IMG-20240603-WA0008.jpg" },
      { url: "https://i.ibb.co/k83xS55/IMG-20240603-WA0009.jpg" },
      { url: "https://i.ibb.co/Gx3QGD6/IMG-20240603-WA0010.jpg" },
      { url: "https://i.ibb.co/HN05Jyx/IMG-20240603-WA0011.jpg" },
      { url: "https://i.ibb.co/s9QhPPh/IMG-20240603-WA0012.jpg" },
      { url: "https://i.ibb.co/B28jzq2/IMG-20240603-WA0013.jpg" },
      { url: "https://i.ibb.co/MhJVTRk/IMG-20240603-WA0014.jpg" },
      { url: "https://i.ibb.co/1bmg28H/IMG-20240603-WA0015.jpg" },
      { url: "https://i.ibb.co/q0NwdYN/IMG-20240603-WA0017.jpg" },
      { url: "https://i.ibb.co/b684vy1/IMG-20240603-WA0018.jpg" },
      { url: "https://i.ibb.co/sbtpvLQ/IMG-20240603-WA0019.jpg" },
      { url: "https://i.ibb.co/qRkChk7/IMG-20240603-WA0020.jpg" },
      { url: "https://i.ibb.co/WzKx2L8/IMG-20240603-WA0021.jpg" },
      { url: "https://i.ibb.co/HCkcQfP/IMG-20240603-WA0022.jpg" },
    ],
  },
  {
    id: 33,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება ბინა ვაკეში",
    nameen: "Apartment for sale in Vake",
    descriptionge:
      "იყიდება ანაგის კომპლექსში ვაკეში, ახალი ევრორემონტით ბინა უცხოვრებელია, სრული ავეჯით და ტექნიკით, კომპლექსში არის სატრენაჟორო დარბაზი და ბასეინი, 24 დაცვით. 100 კვ.მ, სართული 8/11",
    descriptionen:
      "For sale in the Anagi complex in Vake, the apartment is uninhabitable with new Euro renovation, fully furnished and equipped, there is a gym and a swimming pool in the complex, with 24-hour security. 100 sq.m., floor 8/11",
    image: "https://i.ibb.co/whd6c8Q/IMG-20240618-WA0002.jpg",
    imageLg: "https://i.ibb.co/whd6c8Q/IMG-20240618-WA0002.jpg",
    country: "თბილისი",
    addressge: "ვაკე",
    addressen: "Vake",
    bedrooms: "2",
    bathrooms: "2",
    surface: "100 კვ.მ",
    details: {
      repair: "გარემონტებული",
      balcony: true,
      toilet: "2",
      heat: "ცენტრალური",
      parking: false,
      furniture: true,
      elevator: true,
    },
    price: "350 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/8mRfTkS/IMG-20240618-WA0001.jpg" },
      { url: "https://i.ibb.co/whd6c8Q/IMG-20240618-WA0002.jpg" },
      { url: "https://i.ibb.co/NCNjMSG/IMG-20240618-WA0005.jpg" },
      { url: "https://i.ibb.co/1mLFpWc/IMG-20240618-WA0006.jpg" },
      { url: "https://i.ibb.co/Qm5KvDD/IMG-20240618-WA0007.jpg" },
      { url: "https://i.ibb.co/N3hTqq8/IMG-20240618-WA0008.jpg" },
      { url: "https://i.ibb.co/CW4knXz/IMG-20240618-WA0009.jpg" },
      { url: "https://i.ibb.co/2v4zG1v/IMG-20240618-WA0010.jpg" },
      { url: "https://i.ibb.co/JRKmYBn/IMG-20240618-WA0011.jpg" },
      { url: "https://i.ibb.co/wNfNfHs/IMG-20240618-WA0012.jpg" },
      { url: "https://i.ibb.co/GxXd2nF/IMG-20240618-WA0013.jpg" },
      { url: "https://i.ibb.co/BNHf8Cn/IMG-20240618-WA0016.jpg" },
      { url: "https://i.ibb.co/ZmwPKZq/IMG-20240618-WA0018.jpg" },
      { url: "https://i.ibb.co/8N8jCjP/IMG-20240618-WA0019.jpg" },
      { url: "https://i.ibb.co/bHMyGHm/IMG-20240618-WA0021.jpg" },
      { url: "https://i.ibb.co/pn36TNF/IMG-20240618-WA0023.jpg" },
      { url: "https://i.ibb.co/Fn9Dnqd/IMG-20240618-WA0024.jpg" },
      { url: "https://i.ibb.co/X5tkzRB/IMG-20240618-WA0026.jpg" },
      { url: "https://i.ibb.co/8sWJ9vg/IMG-20240618-WA0027.jpg" },
    ],
  },
  {
    id: 34,
    type: "ბინა",
    status: "იყიდება",
    namege: "იყიდება პენტჰაუსი ვაკეში",
    nameen: "Apartment for sale in Vake",
    descriptionge:
      "იყიდება ლამაზი მყუდრო პენტჰაუსი ვაკეში. მრგვალ ბაღთან 5 წუთის სავალში, შატბერაშვილზე. სრული ფართი 300კვმ. შიდა ფართი 220კვმ. 80 კვმ ტერასა ბალკონები. გარშემო შემინულია ვიტრაჟებით და ყველა ოთახს აქვს გასასვლელი აივანზე ულამაზესი ხედებით. პირველ სართული არის ბუხარი და ბარის სექცია. მეორე სართულზე არის უზარბაზარი გარდერობი, დიდი სააბაზანო. მასტერ საძინებელი დიდი ტერასით. ჯაკუზი საიდან იშლება ულამაზესი ხედები. ",
    descriptionen:
      "A nice cozy penthouse in Vake is for sale. 5 minutes' walk from the round garden, on Shatberashvili. The total area is 300 square meters. The internal space is 220 square meters. 80 sq.m. terrace, balconies. It is surrounded by stained-glass windows and all rooms have access to the balcony with beautiful views. The first floor is a fireplace and bar section. On the second floor there is a huge wardrobe, a large bathroom. Master bedroom with large terrace. Jacuzzi with beautiful views.",
    image: "https://i.ibb.co/0ZhHmCq/IMG-20240623-WA0023.jpg",
    imageLg: "https://i.ibb.co/0ZhHmCq/IMG-20240623-WA0023.jpg",
    country: "თბილისი",
    addressge: "ვაკე",
    addressen: "Vake",
    bedrooms: "5",
    bathrooms: "2",
    surface: "300 კვ.მ",
    details: {
      repair: "გარემონტებული",
      balcony: true,
      toilet: "2",
      heat: "ცენტრალური",
      parking: false,
      furniture: true,
      elevator: true,
    },
    price: "700 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/y5WHd60/IMG-20240623-WA0023.jpg" },
      { url: "https://i.ibb.co/SwHyzmb/IMG-20240623-WA0024.jpg" },
      { url: "https://i.ibb.co/5RqL73H/IMG-20240623-WA0026.jpg" },
      { url: "https://i.ibb.co/8r90sQC/IMG-20240623-WA0027.jpg" },
      { url: "https://i.ibb.co/bzKM9kt/IMG-20240623-WA0028.jpg" },
      { url: "https://i.ibb.co/J5MhP2m/IMG-20240623-WA0030.jpg" },
      { url: "https://i.ibb.co/kQCWrGW/IMG-20240623-WA0031.jpg" },
      { url: "https://i.ibb.co/FW9dFqB/IMG-20240623-WA0032.jpg" },
      { url: "https://i.ibb.co/FwcBtLR/IMG-20240623-WA0033.jpg" },
      { url: "https://i.ibb.co/JcCbQP4/IMG-20240623-WA0034.jpg" },
      { url: "https://i.ibb.co/jkgY7JC/IMG-20240623-WA0036.jpg" },
      { url: "https://i.ibb.co/ZTbBx6g/IMG-20240623-WA0037.jpg" },
      { url: "https://i.ibb.co/pJ7vDDB/IMG-20240623-WA0039.jpg" },
      { url: "https://i.ibb.co/m8Zj7RV/IMG-20240623-WA0040.jpg" },
      { url: "https://i.ibb.co/0CTTJFJ/IMG-20240623-WA0041.jpg" },
      { url: "https://i.ibb.co/VSnFVRx/IMG-20240623-WA0042.jpg" },
      { url: "https://i.ibb.co/MnmGzWB/IMG-20240623-WA0044.jpg" },
      { url: "https://i.ibb.co/HTNzt82/IMG-20240623-WA0045.jpg" },
      { url: "https://i.ibb.co/cr86wLt/IMG-20240623-WA0047.jpg" },
      { url: "https://i.ibb.co/CPx9LWT/IMG-20240623-WA0051.jpg" },
    ],
  },
  {
    id: 35,
    type: "House",
    status: "იყიდება",
    namege: "იყიდება სახლი დიღომში",
    nameen: "House for sale in Dighomi",
    descriptionge:
      "იყიდება ახალი აშენებელი უცხოვრებელი სახლი დიღომის 7ში. გამოყენებულია ენერგო-ეფექტური ალუმინის კარ-ფანჯრები ბელგიურ ხის ფასადზე. სანტექნიკა Bellroy Grohe, ჩაშენებული სამზარეულო თავისი ტექნიკით Bosch და Midea. ეზო არის სრულად გამწვანებული და გაყვანილია სარწყავი სისტემა. ",
    descriptionen:
      "A newly built uninhabited house is for sale at Digomi 7. Energy-efficient aluminum doors and windows are used on the Belgian wooden facade. Bellroy Grohe plumbing, built-in kitchen with Bosch and Midea appliances. The yard is fully landscaped and has an irrigation system.",
    image: "https://i.ibb.co/7G4SK25/IMG-20240711-WA0002.jpg",
    imageLg: "https://i.ibb.co/7G4SK25/IMG-20240711-WA0002.jpg",
    country: "თბილისი",
    addressge: "დიღომი",
    addressen: "Dighomi",
    bedrooms: "7",
    bathrooms: "4",
    surface: "320 კვ.მ",
    details: {
      repair: "გარემონტებული",
      balcony: false,
      toilet: "4",
      heat: "ცენტრალური",
      parking: true,
      furniture: true,
      elevator: false,
    },
    price: "700 000",
    agent: {
      image: Agent2,
      name: "თინათინ დგებუაძე",
      phone: "+995 598 99 18 18",
      email: "tikadgebuadze@yahoo.com",
    },
    slides: [
      { url: "https://i.ibb.co/WctHfgw/IMG-20240711-WA0002.jpg" },
      { url: "https://i.ibb.co/tcNrsvp/IMG-20240711-WA0003.jpg" },
      { url: "https://i.ibb.co/B37MmrC/IMG-20240711-WA0006.jpg" },
      { url: "https://i.ibb.co/8PfpnT0/IMG-20240711-WA0008.jpg" },
      { url: "https://i.ibb.co/9rJrCLz/IMG-20240711-WA0009.jpg" },
      { url: "https://i.ibb.co/SBHR0L4/IMG-20240711-WA0014.jpg" },
      { url: "https://i.ibb.co/R6H9zG8/IMG-20240711-WA0019.jpg" },
      { url: "https://i.ibb.co/Qffb2vf/IMG-20240711-WA0020.jpg" },
      { url: "https://i.ibb.co/0CLhG7R/IMG-20240711-WA0021.jpg" },
      { url: "https://i.ibb.co/MPDd1PB/IMG-20240711-WA0022.jpg" },
      { url: "https://i.ibb.co/j8tkDRW/IMG-20240711-WA0023.jpg" },
      { url: "https://i.ibb.co/25sw1rS/IMG-20240711-WA0024.jpg" },
      { url: "https://i.ibb.co/6JnNfqd/IMG-20240711-WA0026.jpg" },
      { url: "https://i.ibb.co/Bz9kc8Z/IMG-20240711-WA0027.jpg" },
      { url: "https://i.ibb.co/t8HPfFX/IMG-20240711-WA0029.jpg" },
      { url: "https://i.ibb.co/hBrm79w/IMG-20240711-WA0030.jpg" },
      { url: "https://i.ibb.co/0C5VVKb/IMG-20240711-WA0031.jpg" },
      { url: "https://i.ibb.co/N2mXM88/IMG-20240711-WA0037.jpg" },
      { url: "https://i.ibb.co/m8q9scv/IMG-20240711-WA0041.jpg" },
      { url: "https://i.ibb.co/3YCLHVY/IMG-20240711-WA0042.jpg" },
      { url: "https://i.ibb.co/WcXpNPd/IMG-20240711-WA0043.jpg" },
      { url: "https://i.ibb.co/bHJPddv/IMG-20240711-WA0044.jpg" },
      { url: "https://i.ibb.co/tHK6cH0/IMG-20240711-WA0045.jpg" },
      { url: "https://i.ibb.co/Z1WnmwX/IMG-20240711-WA0046.jpg" },
    ],
  },
];
