import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiBed, BiBath, BiArea } from 'react-icons/bi'


const House = ({house}) => {
  const { t, i18n } = useTranslation();
  const {image, type, country, address,
     bedrooms, bathrooms, surface,status, price} = house;
  return <div className='bg-white shadow-1 mb-9 p-5 rounded-lg
  w-full max-w-[352px] mx-auto 
  cursor-pointer hover:shadow-2xl transition'>
    <img className='mb-8 w-[21em] object-cover h-[13em]' src={image} alt='' />
    <div className='mb-4 flex gap-x-2 text-sm'>
      <div className='bg-green-500 rounded-full text-white px-3 '>
        {type === 'სახლი' && t('house') }
          {type === 'ბინა' && t('flat') }
        </div>
      <div className='bg-violet-500 rounded-full text-white px-3'>
        {status === 'იყიდება' && t('sale') }
          {status === 'ქირავდება' && t('rent') }
        </div>
    </div>
    <div className='text-lg font-semibold 
    max-w-[260px]'>{house[`name${i18n.language}`]}</div>
   <div className='flex gap-x-4 my-4'>
     <div className='flex items-center text-gray-600 gap-1'>
      <div className='text-[20px]'>
        <BiBed />
      </div>
      <div>
        {bedrooms}
      </div>
      </div>
      <div className='flex items-center text-gray-600 gap-1'>
      <div className='text-[20px]'>
        <BiBath />
      </div>
      <div>
        {bathrooms}
      </div>
      </div>
      <div className='flex items-center text-gray-600 gap-1'>
      <div className='text-[20px]'>
        <BiArea />
      </div>
      <div>
        {surface}
      </div>
      </div>
    </div>
    <div className='text-lg font-semibold text-violet-600 mb-4'>$ {price}</div>
  </div>
};

export default House;
